import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './Home.css'
import About from './About';
import Services from './Services';
import Contact from './Contact';

export class Home extends Component
{

  render ()
  {
    var token = localStorage.getItem( 'token' )
    var tokens = localStorage.getItem( 'tokens' )
    if ( token )
    {
      return <Redirect to="/dashboard" />
    }
    if ( tokens )
    {
      return <Redirect to="/admindashboard" />
    }


    return (
      <div>
        <main>

          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
              <li data-target="#myCarousel" data-slide-to="1"></li>
              <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="/images/hero_1.jpg" className="bd-placeholder-img" width="100%" height="100%" alt="" />
                <div class="container">
                  <div class="carousel-caption text-left">
                    <h1>Welcome to <b>PARAS NET</b></h1>
                    <p>Start Your Own Business with Us.</p>
                    <p><Link class="btn btn-lg btn-primary" to="/register" role="button">Sign up today</Link></p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img src="/images/hero_3.jpg" className="bd-placeholder-img" width="100%" height="100%" alt="" />
                <div class="container">
                  <div class="carousel-caption">
                    <h1>Join to us.</h1>
                    <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                    <p><a class="btn btn-lg btn-primary" href="#" role="button">Learn more</a></p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img src="/images/hero_2.jpg" className="bd-placeholder-img" width="100%" height="100%" alt="" />
                <div class="container">
                  <div class="carousel-caption text-right">
                    <h1>One more for good measure.</h1>
                    <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                    <p><a class="btn btn-lg btn-primary" href="#" role="button">Browse gallery</a></p>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </a>
            <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </a>
          </div>
          <About />
          <Services />
          <Contact/>
          <footer class="container">
            <p class="float-right"><a href="#">Back to top</a></p>
            <p>&copy; 2019-2021, parasnet.co.in Inc. &middot; <a className='nav-item' href="#">Privacy</a> &middot; <a className='nav-item' href="#">Terms</a></p>
          </footer>
        </main>
      </div>
    )
  }
}

export default Home
