import React from 'react'
// import pageNotfind from './Images/pageNoteFind.PNG'

const PageNoteFind = () => {
    return (
        <div className="container">
            <div style={{paddingTop: '30%', textAlign:'center'}}>
                <h1>OOPS! Page not find</h1>
                <h1>404</h1>
            </div>
        </div>
    )
}

export default PageNoteFind
