import React, { Component, Fragment } from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom';


class adminLogin extends Component {

    constructor(props) {
        super(props);
        let loggedIn = false
        const token = localStorage.getItem('tokens')
        if (token) {
            loggedIn = true
        }
        this.state = {
            username: '',
            password: '',
            msg: {},
            loggedIn
        }

        this.changeHandler = this.changeHandler.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }


    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    onSubmit = async e => {
        e.preventDefault();

        const user = {
            username: this.state.username,
            password: this.state.password
        }

        const token = () => {
            localStorage.setItem('tokens', token)
        }
        try {
            const res = await axios.post('/api/admin/login', user, {
                headers: {
                    'Content-type': 'Application/json'
                }
            });
            this.setState({ token: res.data })
            localStorage.setItem('tokens', res.data.token)
            this.setState({
                loggedIn: true
            })
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({ msg: error.response.data.msg })
            } else {
                this.setState({ msg: error.response.data.errors })
            }
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-danger";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
        }
    }


    render() {
        if (this.state.loggedIn) {
            return <Redirect to="/admindashboard" />
        }
        const { msg } = this.state
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 m-auto" style={{ paddingTop: '80px' }}>
                            <div className="card shadow mb-5">
                                <h3 className="card-header bg-dark text-center text-light">Admin</h3>
                                <div className="card-body">
                                    {msg.length ? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>) : null}
                                    
                                    <form onSubmit={this.onSubmit}>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Username</label>
                                        <input type="text" name="username" value={this.state.username} id="username" onChange={this.changeHandler} class="form-control form-control-sm" placeholder='username' />
                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Password</label>
                                        <input type="password" name="password" value={this.state.password} id="password" onChange={this.changeHandler} class="form-control form-control-sm" placeholder="password" />
                                    </div>
                                    <button type="submit" class="btn btn-dark float-right">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default adminLogin;
