import React, { Component } from 'react'
import './Loading.css'
export class Loader extends Component {

    render() {
        return (
            <div class="loader-wrapper">
                <div class="loader">
                    <div class="roller"></div>
                    <div class="roller"></div>
                </div>
                <div class="loader loader-2">
                    <div class="roller"></div>
                    <div class="roller"></div>
                </div>
                <div class="loader loader-3">
                    <div class="roller"></div>
                    <div class="roller"></div>
                </div>
            </div>
        )
    }
}

export default Loader
