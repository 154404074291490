import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'


export class Codes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            codes: {},
            SearchByEmail: "",
            msg: {}
        }
    }

    componentDidMount = async (e) => {
        try {
            // Get token from localStorage
            const token = localStorage.getItem('token')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            await axios.get(`/codes/api/get/codes/foruser`, config)
                .then(res => {
                    this.setState({ codes: res.data })
                })
        } catch (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }
    a = () => {
        var x = document.getElementById('distA');
        if (x.style.display === "none") {
            x.style.display = "block";//width: '100%', height: '500px',  overflowY: 'scroll'
        } else {
            x.style.display = "none";
        }
    }
    b = () => {
        var x = document.getElementById('distB');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    c = () => {
        var x = document.getElementById('distC');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    d = () => {
        var x = document.getElementById('distD');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    e = () => {
        var x = document.getElementById('distE');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    f = () => {
        var x = document.getElementById('distF');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    g = () => {
        var x = document.getElementById('distG');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    h = () => {
        var x = document.getElementById('distH');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    i = () => {
        var x = document.getElementById('distI');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    j = () => {
        var x = document.getElementById('distJ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    k = () => {
        var x = document.getElementById('distK');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    l = () => {
        var x = document.getElementById('distL');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    m = () => {
        var x = document.getElementById('distM');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    n = () => {
        var x = document.getElementById('distN');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    o = () => {
        var x = document.getElementById('distO');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    p = () => {
        var x = document.getElementById('distP');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    q = () => {
        var x = document.getElementById('distQ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    r = () => {
        var x = document.getElementById('distR');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    s = () => {
        var x = document.getElementById('distS');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    t = () => {
        var x = document.getElementById('distT');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    u = () => {
        var x = document.getElementById('distU');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    v = () => {
        var x = document.getElementById('distV');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    w = () => {
        var x = document.getElementById('distW');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    x = () => {
        var x = document.getElementById('distX');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    y = () => {
        var x = document.getElementById('distY');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    z = () => {
        var x = document.getElementById('distZ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    setCity = (city) => {
        var table, tr, td, i, txtValue;
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName('tr');
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[1];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(city.toUpperCase()) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
        document.getElementById('theadtr').style.display = "";
        var y = document.getElementById(`dist${city.substring(0, 1)}`);
        if (table.style.display === "none") {
            table.style.display = "block";
            y.style.display = "none";
            table.style.width = '100%';
            table.style.height = '200px';
            table.style.overflowY = 'scroll';
        } else {
            table.style.display = "none";
        }
    }

    render() {
        const { codes } = this.state;
        return (
            <div>
                <center>
                    <div>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.a} to="#">A</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.b} to="#">B</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.c} to="#">C</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.d} to="#">D</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.e} to="#">E</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.f} to="#">F</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.g} to="#">G</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.h} to="#">H</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.i} to="#">I</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.j} to="#">J</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.k} to="#">K</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.l} to="#">L</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.m} to="#">M</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.n} to="#">N</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.o} to="#">O</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.p} to="#">P</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.r} to="#">R</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.s} to="#">S</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.t} to="#">T</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.u} to="#">U</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.v} to="#">V</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.w} to="#">W</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.y} to="#">Y</Link>
                        <Link style={{ padding: '10px', background: '#696969', color: '#fff' }} onClick={this.z} to="#">Z</Link>
                    </div>
                </center>
                <div style={{ width: '700px' }}>
                    <div id="distA" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 38 number of cities under <b>A</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('ABOHAR')}>ABOHAR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ADILABAD')}>ADILABAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ADONI')}>ADONI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('AGAR')}>AGAR</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('AGARTALA')}>AGARTALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AGRA')}>AGRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AHMEDABAD')}>AHMEDABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AHMEDNAGAR')}>AHMEDNAGAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('AJMER')}>AJMER</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AKOLA')}>AKOLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ALAPPUZHA')}>ALAPPUZHA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ALIGARH')}>ALIGARH</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ALIPURDUAR')}>ALIPURDUAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ALLAHABAD')}>ALLAHABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ALMORA')}>ALMORA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ALUVA')}>ALUVA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ALWAR')}>ALWAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMALAPURAM')}>AMALAPURAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMBALA')}>AMBALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMBEDKAR')}>AMBEDKAR NAGAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('AMBIKAPUR')}>AMBIKAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMETHI')}>AMETHI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMRAVATI')}>AMRAVATI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMRELI')}>AMRELI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('AMRITSAR')}>AMRITSAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AMROHA')}>AMROHA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ANAKAPALLI')}>ANAKAPALLI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ANAND')}>ANAND</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ANANTAPUR')}>ANANTAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ANANTNAG')}>ANANTNAG</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ANDAMAN')}>ANDAMAN &amp; NICOBAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ANGUL')}>ANGUL</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ARA')}>ARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ASANSOL')}>ASANSOL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ASHOK')}>ASHOK NAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AURAIYA')}>AURAIYA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('AURANGABAD')}>AURANGABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('AZAMGARH')}>AZAMGARH</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distB" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 71 number of cities under <b>B</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('BADAUN')}>BADAUN</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('BADDI')}>BADDI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('BAGALKOT')}>BAGALKOT</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('BAHRAICH')}>BAHRAICH</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BAJPUR')}>BAJPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BALAGHAT')}>BALAGHAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BALASORE')}>BALASORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BALLIA')}>BALLIA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BALOTRA')}>BALOTRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BALURGHAT')}>BALURGHAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BANDA')}>BANDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BANGALORE')}>BANGALORE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BANKURA')}>BANKURA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BANSWARA')}>BANSWARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BAPATLA')}>BAPATLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARABANKI')}>BARABANKI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BARAMULLA')}>BARAMULLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARAN')}>BARAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARAUT')}>BARAUT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARDOLI')}>BARDOLI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BAREILLY')}>BAREILLY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARGARH')}>BARGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARIPADA')}>BARIPADA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARMER')}>BARMER</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BARNALA')}>BARNALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARODA')}>BARODA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BARPETA')}>BARPETA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BASTI')}>BASTI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BATALA')}>BATALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BATHINDA')}>BATHINDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BEAWER')}>BEAWER</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BEED')}>BEED</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BEGUSARAI')}>BEGUSARAI</Link>
                                    </td>
                                    <td><Link to="#= onClick={this.beherampur}">BEHERAMPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BEHROR')}>BEHROR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BELGAUM')}>BELGAUM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BELLARY')}>BELLARY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BETTIAH')}>BETTIAH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BETUL')}>BETUL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHADOHI')}>BHADOHI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BHADRAK')}>BHADRAK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHAGALPUR')}>BHAGALPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHANDARA')}>BHANDARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHARATPUR')}>BHARATPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BHARUCH')}>BHARUCH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHATAPARA')}>BHATAPARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHAVNAGAR')}>BHAVNAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHAWANIPATNA')}>BHAWANIPATNA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BHILAI')}>BHILAI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHILWARA')}>BHILWARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHIMAVARAM')}>BHIMAVARAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHIWADI')}>BHIWADI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BHIWANI')}>BHIWANI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHOPAL')}>BHOPAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BHUBANESWAR')}>BHUBANESWAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BIDAR')}>BIDAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BIHARSHARIF')}>BIHARSHARIF</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BIJAPUR')}>BIJAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BIJNORE')}>BIJNORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BIKANER')}>BIKANER</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BILASPUR')}>BILASPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BINA')}>BINA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BOKARO')}>BOKARO</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BOLANGIR')}>BOLANGIR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BONGAIGAON')}>BONGAIGAON</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BULANDSHAHAR')}>BULANDSHAHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BUNDI')}>BUNDI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BURDWAN')}>BURDWAN</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('BURHANPUR')}>BURHANPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BUXAR')}>BUXAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('BYRNIHAT')}>BYRNIHAT</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distC" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 19 number of cities under <b>C</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('CHAMARAJA')}>CHAMARAJA NAGAR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('CHANDAUSI')}>CHANDAUSI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('CHANDIGARH')}>CHANDIGARH</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('CHANDRAPUR')}>CHANDRAPUR</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('CHAPRA')}>CHAPRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHENNAI')}>CHENNAI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHHATTARPUR')}>CHHATTARPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHHINDWARA')}>CHHINDWARA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('CHIKABALLAPUR')}>CHIKABALLAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHIKMAGALUR')}>CHIKMAGALUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHIRALA')}>CHIRALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHITRADURGA')}>CHITRADURGA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('CHITTOOR')}>CHITTOOR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHITTORGARH')}>CHITTORGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CHURU')}>CHURU</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('COIMBATORE')}>COIMBATORE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('COOCH')}>COOCH BEHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CUDDALORE')}>CUDDALORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('CUTTACK')}>CUTTACK</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distD" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 35 number of cities under <b>D</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('DADRI')}>DADRI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('DAHOD')}>DAHOD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('DALHOUSIE')}>DALHOUSIE</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('DAMAN')}>DAMAN</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DAMOH')}>DAMOH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DARBHANGA')}>DARBHANGA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DARJEELING')}>DARJEELING</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DASUYA')}>DASUYA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DAUSA')}>DAUSA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DAVANAGERE')}>DAVANAGERE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DEHRADUN')}>DEHRADUN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DELHI')}>DELHI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DEOBAND')}>DEOBAND</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DEOGARH')}>DEOGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DEORIA')}>DEORIA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DEWAS')}>DEWAS</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DHAMPUR')}>DHAMPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHAMTARI')}>DHAMTARI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHANBAD')}>DHANBAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHAR')}>DHAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DHARAMSHALA')}>DHARAMSHALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHARMANAGAR')}>DHARMANAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHARMAPURI')}>DHARMAPURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHENKANAL')}>DHENKANAL</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DHUBRI')}>DHUBRI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DHULE')}>DHULE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DIBRUGARH')}>DIBRUGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DIGBOI')}>DIGBOI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DIMAPUR')}>DIMAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DINDIGUL')}>DINDIGUL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DULIAJAN')}>DULIAJAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DUMKA')}>DUMKA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('DUNGARPUR')}>DUNGARPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DURGAPUR')}>DURGAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('DWARKA')}>DWARKA</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distE" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 4 number of cities under <b>E</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('ELURU')}>ELURU</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ERODE')}>ERODE</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ETAH')}>ETAH</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ETAWAH')}>ETAWAH</Link>
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                    <div id="distF" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 8 number of cities under <b>F</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('FAIZABAD')}>FAIZABAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('FARIDABAD')}>FARIDABAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('FARIDKOT')}>FARIDKOT</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('FARRUKHABAD')}>FARRUKHABAD</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('FATEHABAD')}>FATEHABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('FATEHPUR')}>FATEHPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('FEROZEPUR')}>FEROZEPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('FIROZABAD')}>FIROZABAD</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distG" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 28 number of cities under <b>G</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('GADAG')}>GADAG</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('GANDHIDHAM')}>GANDHIDHAM</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('GANDHINAGAR')}>GANDHINAGAR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('GANGTOK')}>GANGTOK</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GAUTAM')}>GAUTAM BUDH NAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GAYA')}>GAYA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GHAZIABAD')}>GHAZIABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GHAZIPUR')}>GHAZIPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GIRIDIH')}>GIRIDIH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GOALPARA')}>GOALPARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GOBINDGARH')}>GOBINDGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GODHRA')}>GODHRA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GOKAK')}>GOKAK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GOLAGHAT')}>GOLAGHAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GONDA')}>GONDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GONDIA')}>GONDIA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GORAKHPUR')}>GORAKHPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GUDIWADA')}>GUDIWADA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GUDUR')}>GUDUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GULBARGA')}>GULBARGA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GUNA')}>GUNA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GUNTAKAL')}>GUNTAKAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GUNTUR')}>GUNTUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GURDASPUR')}>GURDASPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('GURGAON')}>GURGAON</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GURUVAYOOR')}>GURUVAYOOR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GUWAHATI')}>GUWAHATI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('GWALIOR')}>GWALIOR</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distH" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 23 number of cities under <b>H</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('HALDIA')}>HALDIA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('HALDWANI')}>HALDWANI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('HAMIRPUR')}>HAMIRPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('HANUMANGARH')}>HANUMANGARH</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('HAPUR')}>HAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HARDA')}>HARDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HARDOI')}>HARDOI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HARDWAR')}>HARDWAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('HASSAN')}>HASSAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HATHRAS')}>HATHRAS</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HAVERI')}>HAVERI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HAZARIBAGH')}>HAZARIBAGH</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('HIMMATNAGAR')}>HIMMATNAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HINDUPUR')}>HINDUPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HINGOLI')}>HINGOLI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HISSAR')}>HISSAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('HOOGHLY')}>HOOGHLY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HOOGLY')}>HOOGLY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HOSHIARPUR')}>HOSHIARPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HOSPET')}>HOSPET</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('HOSUR')}>HOSUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HUBLI')}>HUBLI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('HYDERABAD')}>HYDERABAD</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distI" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 4 number of cities under <b>I</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('ICHALKARANJI')}>ICHALKARANJI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('IMPHAL')}>IMPHAL</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('INDORE')}>INDORE</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ITARSI')}>ITARSI</Link>
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                    <div id="distJ" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 26 number of cities under <b>J</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('JABALPUR')}>JABALPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('JAGDALPUR')}>JAGDALPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('JAGRAON')}>JAGRAON</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('JAIPUR')}>JAIPUR</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JAISALMER')}>JAISALMER</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JAJPUR')}>JAJPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JALANDHAR')}>JALANDHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JALGAON')}>JALGAON</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JALNA')}>JALNA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JALORE')}>JALORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JALPAIGURI')}>JALPAIGURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JAMMU')}>JAMMU</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JAMNAGAR')}>JAMNAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JAMSHEDPUR')}>JAMSHEDPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JANJGIR')}>JANJGIR CHAMPA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JAUNPUR')}>JAUNPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JEYPORE')}>JEYPORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JHABUA')}>JHABUA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JHALAWAR')}>JHALAWAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JHANSI')}>JHANSI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JHARSUGUDA')}>JHARSUGUDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JHUNJHUNU')}>JHUNJHUNU</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JIND')}>JIND</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JODHPUR')}>JODHPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('JORHAT')}>JORHAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('JUNAGADH')}>JUNAGADH</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distK" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 59 number of cities under <b>K</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('KADAPA')}>KADAPA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('KAITHAL')}>KAITHAL</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('KAKINADA')}>KAKINADA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('KALINGPONG')}>KALINGPONG</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KALPETTA')}>KALPETTA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KALYAN')}>KALYAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KANCHEEPURAM')}>KANCHEEPURAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KANKER')}>KANKER</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KANNAUJ')}>KANNAUJ</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KANNUR')}>KANNUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KANPUR')}>KANPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KAPURTHALA')}>KAPURTHALA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KARAIKUDI')}>KARAIKUDI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KARAULI')}>KARAULI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KARIMGANJ')}>KARIMGANJ</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KARIMNAGAR')}>KARIMNAGAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KARNAL')}>KARNAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KARUR')}>KARUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KARWAR')}>KARWAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KASARGOD')}>KASARGOD</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KASGANJ')}>KASGANJ</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KASHIPUR')}>KASHIPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KATHUA')}>KATHUA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KATIHAR')}>KATIHAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KATNI')}>KATNI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KATRA')}>KATRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KAUSHAMBI')}>KAUSHAMBI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KAWARDHA')}>KAWARDHA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KENDRAPADA')}>KENDRAPADA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KEONJHAR')}>KEONJHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHAMGAON')}>KHAMGAON</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHAMMAM')}>KHAMMAM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KHANDWA')}>KHANDWA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHANNA')}>KHANNA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHARGONE')}>KHARGONE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHATAULI')}>KHATAULI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KHATIMA')}>KHATIMA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KHURDA')}>KHURDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KISHENGARH')}>KISHENGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOCHI')}>KOCHI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KODERMA')}>KODERMA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOLAR')}>KOLAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOLHAPUR')}>KOLHAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOLKATA')}>KOLKATA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KOLLAM')}>KOLLAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOPPAL')}>KOPPAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KORBA')}>KORBA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOTA')}>KOTA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KOTDWAR')}>KOTDWAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOTHAGUDEM')}>KOTHAGUDEM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOTTAYAM')}>KOTTAYAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KOZHIKODE')}>KOZHIKODE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="# onClick={this.krishnagiri}">KRISHNAGIRI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KUDAL')}>KUDAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KULLU')}>KULLU</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KUMBAKONAM')}>KUMBAKONAM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('KURNOOL')}>KURNOOL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KURUKSHETRA')}>KURUKSHETRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('KUSHINAGAR')}>KUSHINAGAR</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distL" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 7 number of cities under <b>L</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('LAKHIMPUR')}>LAKHIMPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('LAKHISARAI')}>LAKHISARAI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('LALITPUR')}>LALITPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('LATUR')}>LATUR</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('LUCKNOW')}>LUCKNOW</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('LUDHIANA')}>LUDHIANA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('LUNAWADA')}>LUNAWADA</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distM" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 45 number of cities under <b>M</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('MACHILIPATNAM')}>MACHILIPATNAM</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('MADANAPALLE')}>MADANAPALLE</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('MADHUBANI')}>MADHUBANI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('MADIKERI')}>MADIKERI</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MADURAI')}>MADURAI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MAHABUBNAGAR')}>MAHABUBNAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MAHARAJGANJ')}>MAHARAJGANJ</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MAHASAMUND')}>MAHASAMUND</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MAINPURI')}>MAINPURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MAKRANA')}>MAKRANA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MALDA')}>MALDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MALEGAON')}>MALEGAON</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MALERKOTLA')}>MALERKOTLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANCHIRIYAL')}>MANCHIRIYAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANDI')}>MANDI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANDLA')}>MANDLA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MANDSAUR')}>MANDSAUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANDYA')}>MANDYA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANENDRAGARH')}>MANENDRAGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANGALDOI')}>MANGALDOI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MANGALORE')}>MANGALORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MANSA')}>MANSA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MARGAO')}>MARGAO</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MARIGAON')}>MARIGAON</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MATHURA')}>MATHURA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MAU')}>MAU</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MEERUT')}>MEERUT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MEHSANA')}>MEHSANA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MIDNAPUR')}>MIDNAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MIRZAPUR')}>MIRZAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MODASA')}>MODASA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MOGA')}>MOGA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MORADABAD')}>MORADABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MORBI')}>MORBI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MORENA')}>MORENA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MOTIHARI')}>MOTIHARI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MOUNT')}>MOUNT ABU</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('Mumbai')}>Mumbai</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MUKTSAR')}>MUKTSAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MUMBAI')}>MUMBAI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MUNGER')}>MUNGER</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MURSHIDABAD')}>MURSHIDABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('MUZAFFARNAGAR')}>MUZAFFARNAGAR</Link>
                                    </td>
                                    <td><Link to="#" muzaffarpur>MUZAFFARPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('MYSORE')}>MYSORE</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distN" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 36 number of cities under <b>N</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('NABHA')}>NABHA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('NADIA')}>NADIA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('NADIAD')}>NADIAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('NAGAON')}>NAGAON</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NAGAPATTINAM')}>NAGAPATTINAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAGAUR')}>NAGAUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAGERCOIL')}>NAGERCOIL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAGPUR')}>NAGPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NAHAN')}>NAHAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAKODAR')}>NAKODAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NALBARI')}>NALBARI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NALGONDA')}>NALGONDA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NAMAKKAL')}>NAMAKKAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NANDED')}>NANDED</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NANDURBAR')}>NANDURBAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NANDYAL')}>NANDYAL</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NANITAL')}>NANITAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NARASARAOPET')}>NARASARAOPET</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NARNAUL')}>NARNAUL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NARSINGPUR')}>NARSINGPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NASHIK')}>NASHIK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NASIK')}>NASIK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAVSARI')}>NAVSARI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NAWANSHAHAR')}>NAWANSHAHAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NAZIBABAD')}>NAZIBABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NEEM')}>NEEM KA THANA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NEEMUCH')}>NEEMUCH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NELLORE')}>NELLORE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NIPPANI')}>NIPPANI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NIRMAL')}>NIRMAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NIZAMABAD')}>NIZAMABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NOHAR')}>NOHAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('NOIDA')}>NOIDA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NOKHA')}>NOKHA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NORTH')}>NORTH LAKHIMPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('NURPUR')}>NURPUR</Link>
                                    </td>
                                </tr>


                            </tbody></table>
                    </div>
                    <div id="distO" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 4 number of cities under <b>O</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('ONGOLE')}>ONGOLE</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('OOTY')}>OOTY</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('ORAI')}>ORAI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('OSMANABAD')}>OSMANABAD</Link>
                                </td>
                            </tr>


                            </tbody></table>
                    </div>
                    <div id="distP" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 36 number of cities under <b>P</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('PALAKKAD')}>PALAKKAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('PALAKOL')}>PALAKOL</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('PALAMPUR')}>PALAMPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('PALANPUR')}>PALANPUR</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PALGHAR')}>PALGHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PALI')}>PALI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PANAJI')}>PANAJI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PANCHKULA')}>PANCHKULA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PANDHARPUR')}>PANDHARPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PANIPAT')}>PANIPAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PANVEL')}>PANVEL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PARADEEP')}>PARADEEP</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PARBHANI')}>PARBHANI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PARWANOO')}>PARWANOO</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PATAN')}>PATAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PATHANKOT')}>PATHANKOT</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PATIALA')}>PATIALA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PATNA')}>PATNA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PERAMBALUR')}>PERAMBALUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PETLAD')}>PETLAD</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PHAGWARA')}>PHAGWARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PHALODI')}>PHALODI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PHULBANI')}>PHULBANI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PILIBHIT')}>PILIBHIT</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PITHORAGARH')}>PITHORAGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('POLLACHI')}>POLLACHI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PORBANDAR')}>PORBANDAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PRATAPGARH')}>PRATAPGARH</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PRODDATUR')}>PRODDATUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PUDUCHERRY')}>PUDUCHERRY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PUDUKKOTTAI')}>PUDUKKOTTAI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PUNE')}>PUNE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('PURI')}>PURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PURNEA')}>PURNEA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PURULIA')}>PURULIA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('PUTTUR')}>PUTTUR</Link>
                                    </td>
                                </tr>


                            </tbody></table>
                    </div>
                    <div id="distR" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 31 number of cities under <b>R</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('RAEBARELI')}>RAEBARELI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('RAICHUR')}>RAICHUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('RAIGANJ')}>RAIGANJ</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('RAIGARH')}>RAIGARH</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('RAIPUR')}>RAIPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAISEN')}>RAISEN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAJAHMUNDRY')}>RAJAHMUNDRY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAJAMAHENDRAVARAM')}>RAJAMAHENDRAVARAM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('RAJGARH')}>RAJGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAJKOT')}>RAJKOT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAJNANDGAON')}>RAJNANDGAON</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAJPURA')}>RAJPURA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('RAJSAMAND')}>RAJSAMAND</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAM')}>RAM NAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAMANATHAPURAM')}>RAMANATHAPURAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAMGARH')}>RAMGARH</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('RAMNAGAR')}>RAMNAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAMPUR')}>RAMPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAMPUR')}>RAMPUR BUSHAHR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RANCHI')}>RANCHI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('RATLAM')}>RATLAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RATNAGIRI')}>RATNAGIRI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RAYAGADA')}>RAYAGADA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('REWA')}>REWA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('REWARI')}>REWARI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RISHIKESH')}>RISHIKESH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ROHTAK')}>ROHTAK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ROORKEE')}>ROORKEE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ROPAR')}>ROPAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('ROURKELA')}>ROURKELA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('RUDRAPUR')}>RUDRAPUR</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>


                            </tbody></table>
                    </div>
                    <div id="distS" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 59 number of cities under <b>S</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('SAGAR')}>SAGAR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('SAHARANPUR')}>SAHARANPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('SAHARSA')}>SAHARSA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('SAHIBGANJ')}>SAHIBGANJ</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SALEM')}>SALEM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SAMANA')}>SAMANA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SAMASTIPUR')}>SAMASTIPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SAMBA')}>SAMBA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SAMBALPUR')}>SAMBALPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SAMBHAL')}>SAMBHAL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SANGAREDDY')}>SANGAREDDY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SANGLI')}>SANGLI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SANGRUR')}>SANGRUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SASARAM')}>SASARAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SATARA')}>SATARA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SATNA')}>SATNA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SAWAI')}>SAWAI MADHOPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SEHORE')}>SEHORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SENDHWA')}>SENDHWA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SEONI')}>SEONI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SHAHDOL')}>SHAHDOL</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHAHJAHANPUR')}>SHAHJAHANPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHAJAPUR')}>SHAJAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHAMLI')}>SHAMLI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SHILLONG')}>SHILLONG</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHIMLA')}>SHIMLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHIMOGA')}>SHIMOGA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SHIVPURI')}>SHIVPURI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SIBSAGAR')}>SIBSAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIDDHARTH')}>SIDDHARTH NAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIDDIPET')}>SIDDIPET</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIKAR')}>SIKAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SILCHAR')}>SILCHAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SILIGURI')}>SILIGURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SILVASSA')}>SILVASSA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SINGRAULI')}>SINGRAULI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SIRHIND')}>SIRHIND</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIROHI')}>SIROHI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIRSA')}>SIRSA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIRSI')}>SIRSI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SITAMARHI')}>SITAMARHI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SITAPUR')}>SITAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SIWAN')}>SIWAN</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SOLAN')}>SOLAN</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SOLAPUR')}>SOLAPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SONBHADRA')}>SONBHADRA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SONEPAT')}>SONEPAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SRIGANGANAGAR')}>SRIGANGANAGAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SRIKAKULAM')}>SRIKAKULAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SRINAGAR')}>SRINAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SULTANPUR')}>SULTANPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SUMERPUR')}>SUMERPUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SUNAM')}>SUNAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SUNDER')}>SUNDER NAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SURAT')}>SURAT</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SURATGARH')}>SURATGARH</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('SURENDRANAGAR')}>SURENDRANAGAR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SURI')}>SURI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('SURYAPET')}>SURYAPET</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>


                            </tbody></table>
                    </div>
                    <div id="distT" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 31 number of cities under <b>T</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('TADEPALLIGUDEM')}>TADEPALLIGUDEM</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('TAMBARAM')}>TAMBARAM</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('TANUKU')}>TANUKU</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('TARN')}>TARN TARAN</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="A#" onClick={this.tehri}>TEHRI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TENALI')}>TENALI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TEZPUR')}>TEZPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('THANE')}>THANE</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('THANJAVUR')}>THANJAVUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('THENI')}>THENI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('THIRUVALLA')}>THIRUVALLA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('THIRUVANANTHAPURAM')}>THIRUVANANTHAPURAM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('THODUPUZHA')}>THODUPUZHA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('THRISSUR')}>THRISSUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIKAMGARH')}>TIKAMGARH</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TINSUKIA')}>TINSUKIA</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('TIPTUR')}>TIPTUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUCHENGODE')}>TIRUCHENGODE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUNELVELI')}>TIRUNELVELI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUPATHI')}>TIRUPATHI</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('TIRUPATI')}>TIRUPATI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUPPUR')}>TIRUPPUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUR')}>TIRUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TIRUVALLUR')}>TIRUVALLUR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('TIRUVANNAMALAI')}>TIRUVANNAMALAI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TONK')}>TONK</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TRICHY')}>TRICHY</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TRIVANDRUM')}>TRIVANDRUM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('TUMKUR')}>TUMKUR</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TUNI')}>TUNI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('TUTICORIN')}>TUTICORIN</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distU" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 7 number of cities under <b>U</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('UDAIPUR')}>UDAIPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('UDHAMPUR')}>UDHAMPUR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('UDUPI')}>UDUPI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('UJJAIN')}>UJJAIN</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('UMARIA')}>UMARIA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('UNA')}>UNA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('UNNAO')}>UNNAO</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distV" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 14 number of cities under <b>V</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('VADODARA')}>VADODARA</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('VAISHALI')}>VAISHALI</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('VALSAD')}>VALSAD</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('VAPI')}>VAPI</Link>
                                </td>
                            </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('VARANASI')}>VARANASI</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VELLORE')}>VELLORE</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VIDISHA')}>VIDISHA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VIJAYANAGARAM')}>VIJAYANAGARAM</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('VIJAYAWADA')}>VIJAYAWADA</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VIKARABAD')}>VIKARABAD</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VILLUPURAM')}>VILLUPURAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VIRUDHUNAGAR')}>VIRUDHUNAGAR</Link>
                                    </td>
                                </tr><tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('VISAKHAPATNAM')}>VISAKHAPATNAM</Link>
                                    </td>
                                    <td><Link to="#" onClick={() => this.setCity('VISHAKHAPATNAM')}>VISHAKHAPATNAM</Link>
                                    </td>
                                </tr>
                            </tbody></table>
                    </div>
                    <div id="distW" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 2 number of cities under <b>W</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('WARANGAL')}>WARANGAL</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('WARDHA')}>WARDHA</Link>
                                </td>
                            </tr>
                            </tbody></table>
                    </div>
                    <div id="distY" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 3 number of cities under <b>Y</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody><tr align="left">
                                <td><Link to="#" onClick={() => this.setCity('YADGIR')}>YADGIR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('YAMUNANAGAR')}>YAMUNANAGAR</Link>
                                </td>
                                <td><Link to="#" onClick={() => this.setCity('YAVATMAL')}>YAVATMAL</Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="distZ" style={{ display: 'none' }} >
                        <br />
                        <p>Search has found 1 number of cities under <b>Z</b></p>
                        <br />
                        <table border="0" width="100%">
                            <tbody>
                                <tr align="left">
                                    <td><Link to="#" onClick={() => this.setCity('ZIRA')}>ZIRA</Link></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="App mt-3" id="codes" style={{ width: '700px' }}>
                    <table className="table table-bordered text-center" width="100%" id="myTable" style={{ display: 'none' }} align="center">
                        <thead>
                            <tr id='theadtr'>
                                <td width="5%" valign="top"><b>Select</b></td>
                                <td valign="top" width="15%"><b>Ward/Circle/Range/ Commissioner</b></td>
                                <td valign="top" width="7%"><b>Discription</b></td>
                                <td valign="top" width="7%"><b>Area Code</b></td>
                                <td valign="top" width="7%"><b>AO Type</b></td>
                                <td valign="top" width="7%"><b>Range Code</b></td>
                                <td valign="top" width="7%"><b>AO Number</b></td>
                            </tr>
                        </thead>
                        {codes.length ? codes.map((code, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <td><input className="form-check-input" type="radio" name="aoDetail" onClick={() => this.props.update({ areacode: code.areacode, aotype: code.aotype, rangecode: code.rangecode, aonumber: code.aonumber })} value={`${code.areacode}~${code.aotype}~${code.rangecode}~${code.aonumber}`} /></td>
                                        <td>{code.discription}</td>
                                        <td>{code.discription1}</td>
                                        <td>{code.areacode}</td>
                                        <td>{code.aotype}</td>
                                        <td>{code.rangecode}</td>
                                        <td>{code.aonumber}</td>
                                    </tr>
                                </tbody>
                            )
                        }) : <h5>Please wait....</h5>}
                    </table>
                </div>
            </div>
        );
    }
}

export default Codes