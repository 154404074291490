import React, {Component} from 'react'
import axios from 'axios';

export class Setting extends Component {
    constructor(props) {
        super(props);
        this.state={
            bankname: '',
            ifsccode: '',
            accountnumber: '',
            msg: {}
        }
        this.changeHandle=this.changeHandle.bind(this)
        this.UserPassAdd=this.UserPassAdd.bind(this)
    }

    changeHandle=(e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    UserPassAdd=async (e) => {
        e.preventDefault();

        const newusernamepassword={
            username1: this.state.username1,
            password1: this.state.password1,
            username2: this.state.username2,
            password2: this.state.password2,
            username3: this.state.username3,
            password3: this.state.password3,
            startfromNuber: this.state.startfromNuber,
            panManulVerify: this.state.panManulVerify
        }

        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "Application/json"
                }
            };

            if (token) {
                config.headers['x-admin-token']=token;
            }
            const res=await axios.put(`/api/add/username/password/backoffice/${this.state._id}`, newusernamepassword, config);
            if (res.status == 200) {
                // console.log(res.data);
                this.setState({msg: res.data.msg})
                var newToast=document.getElementById("snackbar");
                newToast.className="show bg-success";
                setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
            }
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({msg: error.response.data.msg})
            } else {
                this.setState({msg: error.response.data.errors})
            }
            var newToast=document.getElementById("snackbar");
            newToast.className="show bg-danger";
            setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);

        }

    }

    async componentDidMount() {
        try {

            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token']=token;
            }
            await axios.get(`/api/get/username/password`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            console.log(error.response.status);
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }

    render() {
        const {msg}=this.state;
        return (
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className='container'>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className="card shadow-sm">
                                <div className="card-header bg-dark">
                                    <h3 className="text-light">Username and Password Add</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.UserPassAdd}>
                                        {msg.length? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>):null}
                                        <div className='row'>
                                            <div className="mb-3 col-md-3">
                                                <label className="col-form-label" for="username1" className="form-label">Username 1</label>
                                                <input type="text" className="form-control form-control-sm" id="username1" onChange={this.changeHandle} value={this.state.username1} name="username1" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label className="col-form-label" for="bankname" className="form-label">password 1</label>
                                                <input type="text" className="form-control form-control-sm" id="bankname" onChange={this.changeHandle} value={this.state.password1} name="password1" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label for="username2" className="form-label">Username 2</label>
                                                <input type="text" name="username2" value={this.state.username2} onChange={this.changeHandle} className="form-control form-control-sm" id="username2" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label for="password2" className="form-label">Password2</label>
                                                <input type="text" name="password2" value={this.state.password2} onChange={this.changeHandle} className="form-control form-control-sm" id="password2" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label for="username3" className="form-label">Username 3</label>
                                                <input type="text" name="username3" value={this.state.username3} onChange={this.changeHandle} className="form-control form-control-sm" id="username2" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label for="password3" className="form-label">Password3</label>
                                                <input type="text" name="password3" value={this.state.password3} onChange={this.changeHandle} className="form-control form-control-sm" id="password3" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                                <label for="numberoffromstart" className="form-label">Number of from Start</label>
                                                <input type="number" name="startfromNuber" value={this.state.startfromNuber} onChange={this.changeHandle} className="form-control form-control-sm" id="password3" />
                                            </div>
                                            <button type="submit" className="btn btn-dark">Add Username Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5">
                            <div className="card shadow-sm">
                                <div className="card-header bg-dark">
                                    <h4 className="text-light text-center">Choose Pan Verify</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.UserPassAdd}>
                                        <label htmlFor="manualPanVerify" className="form-label">Choose Option</label>
                                        <select name="panManulVerify" className='form-control form-control-sm' value={this.state.panManulVerify} onChange={this.changeHandle} id="panManulVerify">
                                            <option>Select</option>
                                            <option value="YES">YES</option>
                                            <option value="NO">NO</option>
                                        </select>
                                        <button className="btn btn-dark mt-3">Select</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default Setting
