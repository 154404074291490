import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';


const AllUsers = () => {
    const [users, setUsers] = useState([]);
    const [msg, setMsg] = useState([]);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState([]);
    const [loader, setLoader] = useState(false);

    const history = useHistory();
    const nextPage = () => {
        setSkip(skip + limit)
        console.log(skip)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }

    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const GetData = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/api/allusers?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    setLoader(false)
                    setUsers(res.data)
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                history.push('/Admin')
            }
        }
    }
    /**@Search Users */
    const changeHandle = async (e) => {
        setSearch(e.target.value.toUpperCase())
        if (e.target.name === 'search') {
            try {
                // Get token from localStorage
                const token = localStorage.getItem('tokens')
                // Headers
                const config = {
                    headers: {
                        "Content-type": "application/json"
                    }
                };
                if (token) {
                    config.headers['x-admin-token'] = token;
                }
                const res = await axios.post(`/api/search`, { text: search }, config)
                const reverseData = res.data.reverse()
                setUsers(reverseData)
            } catch (error) {
                setMsg(error.response.data)
                if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    history.push('/Admin')
                }
            }
        }
    }
    useEffect(() => {
        GetData(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="input-group input-group-sm mb-3 col-md-2">
                        <span className="input-group-text col-md-2" id="inputGroup-sizing-sm">Search</span>
                        <input type="text" className="form-control" value={search}
                            onChange={changeHandle} aria-label="Sizing example input" name='search' aria-describedby="inputGroup-sizing-sm" />
                    </div>
                    <div className="card mb-5">
                        <div className="card-header bg-dark">
                            <h4 className="text text-center text-light">Users</h4>
                        </div>
                        <div className="card-body shadow">
                            <div className="table-responsive">
                                <table id='myTable' className="table shadow table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Username</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Aadhar number</th>
                                        </tr>
                                    </thead>
                                    {users.length ? users.map((user, index) => {
                                        return <tbody key={index}>
                                            <tr>
                                                <td>{index + 1} </td>
                                                <td>
                                                    <Link target='_blank' to={`/user/${user._id}`}>{user.username}</Link>
                                                </td>
                                                <td>{user.fullname}</td>
                                                <td>{user.email}</td>
                                                <td>{user.mobile}</td>
                                                <td>{user.aadharnumber}</td>
                                            </tr>
                                        </tbody>
                                    }) : null}
                                </table>
                                {loader ? <Loader /> : null}
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={previousPage} className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={nextPage} className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AllUsers
