import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './Toast.css'

export class UserSideNav extends Component {
  logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    this.props.history.push('/login');
  }
  render() {
    var header = document.getElementsByTagName('li')
    for (var i = 0; i < header.length; i++) {
      header[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
              <div className="position-sticky pt-3">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/dashboard">
                      <i className="material-icons align-bottom">dashboard &nbsp;</i>
                        Dashboard
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">
                      <i className="material-icons align-bottom">account_circle &nbsp;</i>
                        Profile
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/payment">
                      <i className="material-icons align-bottom">account_balance &nbsp;</i>
                        Payment Add
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/mypayments">
                      <i className="material-icons align-bottom">account_balance_wallet &nbsp;</i>
                        Payments
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/NewPan">
                      <i className="material-icons align-bottom">credit_card &nbsp;</i>
                        Newpan
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/mypans">
                      <i className="material-icons align-bottom">credit_card &nbsp;</i>
                        Pans
                      </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/paymenthistory">
                      <i className="material-icons align-bottom">history &nbsp;</i>
                        Payment History
                      </Link>
                  </li>
                  <li className="nav-item text-nowrap">
                    <a className="nav-link" onClick={this.logOut.bind(this)} href="#">
                      <i className="material-icons align-bottom">settings_power &nbsp;</i>
                      Sign out</a>
                  </li>
                </ul>
              </div>
            </nav>
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">User Dashboard</h1>
              </div>
              {/* <canvas className="my-4 w-100" id="myChart" width="900" height="380"></canvas> */}
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(UserSideNav)
