import React, { Component } from 'react'

export class Contact extends Component {
    render() {
        return (
            <div>
                <div className="container" style={{ marginTop: '70px' }}>
                    <div className="card-header bg-info">
                        <h4 className="text-center text-light">CONTACT US</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <h5>PARAS NET CORPORATION</h5>
                                    <p>near Tahasil Dhanaura Mandi amroha UP (244231)</p>
                                    <br /><br />
                                    <h5>Call Us</h5>
                                    <p>+91 94121 41222</p>
                                    <br /><br />
                                    <h5>Email-ID</h5>
                                    <p>
                                        <a className='nav-link' href="mailto:tusharkaushik979@gmail.com">tusharkaushik989@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <form>
                                    <div className="form-group">
                                        <label className='col-form-label' htmlFor="fullname">Full Name</label>
                                        <input type="fullname" className='form-control form-control-sm' name='fullname' />
                                    </div>
                                    <div className="form-group">
                                        <label className='col-form-label' htmlFor="email">Email</label>
                                        <input type="email" className='form-control form-control-sm' name='email' />
                                    </div>
                                    <div className="form-group">
                                        <label className='col-form-label' htmlFor="message">Message</label>
                                        <textarea name="message" placeholder="Message..............." className="form-control" id="message" cols="25" rows="10"></textarea>
                                    </div>
                                    <input type="Submit" className="btn btn-info text-light mt-1" value="Submit"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
