import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './Pan.css';
import Codes from './Codes';
import axios from 'axios';

const Newpan = () => {
	const history = useHistory();
	const [panData, setPanData] = useState({
		loading: '',
		aocode: '',
		ao: '',
		range: '',
		aonumber: '',
		application: '',
		old_pan: '',
		category: 'INDIVIDUAL',
		applicant: '',
		firstname: '',
		middlename: '',
		lastname: '',
		ffirstname: '',
		fmiddlename: '',
		flastname: '',
		cardHolder: '',
		dob: '',
		contect_number: '',
		email: '',
		proofid: 'AADHAAR CARD',
		proofadd: 'AADHAAR CARD',
		proofdob: 'AADHAAR CARD',
		gender: '',
		adhar_number: '',
		address_f: '',
		address_v: '',
		address_p: '',
		address_divi: '',
		address_d: '',
		state: '',
		pin_code: '',
		parents: '',
		token: null,
		invalidImage: '',
		invalidSig: '',
		invalidPdf: '',
		msg: []
	})
	const changeHandler = (e) => {
		setPanData({ ...panData, [e.target.name]: e.target.value.toUpperCase() })
		const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
		const validFirstname = new RegExp('^([a-zA-Z]){3,}$');
		const validAddress = new RegExp('^([a-zA-Z ]){3,}$');
		const mobile = new RegExp('^([0-9]).{9}$');
		const adhar_number = new RegExp('^([0-9]).{11}$');
		const regexPan = /^\b[A-Za-z]{5}\d{4}[A-Za-z]{1}\b$/i;
		const pin_code = new RegExp('^([0-9]).{5}$');
		const dob = new RegExp("^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$")
		if (e.target.name == 'application') {
			if (e.target.value == "CORECATION") {
				document.getElementById("old_pan").style.visibility = "visible";
				document.getElementById("oldPan").style.visibility = "visible";
			} else {
				document.getElementById("oldPan").style.visibility = "hidden";
				document.getElementById("old_pan").style.visibility = "hidden";
			}
		} else if (e.target.name == 'old_pan') {
			if (regexPan.test(e.target.value)) {
				document.getElementById('old_pan').className = '';
			} else {
				document.getElementById('old_pan').className = 'error';
			}
		} else if (e.target.name == 'firstname') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('firstname').className = '';
			} else {
				document.getElementById('firstname').className = 'error';
			}
		} else if (e.target.name == 'middlename') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('middlename').className = '';
			} else {
				document.getElementById('middlename').className = 'error';
			}
		} else if (e.target.name == 'lastname') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('lastname').className = '';
			} else {
				document.getElementById('lastname').className = 'error';
			}
		} else if (e.target.name == 'lastname') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('lastname').className = '';
			} else {
				document.getElementById('lastname').className = 'error';
			}
		} else if (e.target.name == 'ffirstname') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('ffirstname').className = '';
			} else {
				document.getElementById('ffirstname').className = 'error';
			}
		} else if (e.target.name == 'fmiddlename') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('fmiddlename').className = '';
			} else {
				document.getElementById('fmiddlename').className = 'error';
			}
		} else if (e.target.name == 'flastname') {
			if (validFirstname.test(e.target.value)) {
				document.getElementById('flastname').className = '';
			} else {
				document.getElementById('flastname').className = 'error';
			}
		} else if (e.target.name == 'dob') {
			// function getAge(dateString) {
			// 	var ageInMilliseconds = new Date() - new Date(dateString);
			// 	return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
			// }
			// const year = getAge(e.target.value)
			// if (year < 18) {
			// 	alert('It is minor')
			// }
			if (dob.test(e.target.value)) {
				document.getElementById('dob').className = '';
			} else {
				document.getElementById('dob').className = 'error';
			}
		} else if (e.target.name == 'contect_number') {
			if (mobile.test(e.target.value)) {
				document.getElementById('contect_number').className = '';
			} else {
				document.getElementById('contect_number').className = 'error';
			}
		} else if (e.target.name == 'adhar_number') {
			if (adhar_number.test(e.target.value)) {
				document.getElementById('adhar_number').className = '';
			} else {
				document.getElementById('adhar_number').className = 'error';
			}
		} else if (e.target.name == 'email') {
			if (validEmail.test(e.target.value)) {
				document.getElementById('email').className = '';
			} else {
				document.getElementById('email').className = 'error';
			}
		} else if (e.target.name == 'pin_code') {
			if (pin_code.test(e.target.value)) {
				document.getElementById('pin_code').className = '';
			} else {
				document.getElementById('pin_code').className = 'error';
			}
		} else if (e.target.name == 'address_f') {
			if (validAddress.test(e.target.value)) {
				document.getElementById('address_f').className = '';
			} else {
				document.getElementById('address_f').className = 'error';
			}
		} else if (e.target.name == 'address_v') {
			if (validAddress.test(e.target.value)) {
				document.getElementById('address_v').className = '';
			} else {
				document.getElementById('address_v').className = 'error';
			}
		} else if (e.target.name == 'address_p') {
			if (validAddress.test(e.target.value)) {
				document.getElementById('address_p').className = '';
			} else {
				document.getElementById('address_p').className = 'error';
			}
		} else if (e.target.name == 'address_divi') {
			if (validAddress.test(e.target.value)) {
				document.getElementById('address_divi').className = '';
			} else {
				document.getElementById('address_divi').className = 'error';
			}
		} else if (e.target.name == 'address_d') {
			if (validAddress.test(e.target.value)) {
				document.getElementById('address_d').className = '';
			} else {
				document.getElementById('address_d').className = 'error';
			}
		}
		if (e.target.className == 'error') {
			const submitBtn = document.getElementById('submit-btn');
			submitBtn.disabled = true;
		} else {
			const submitBtn = document.getElementById('submit-btn');
			submitBtn.disabled = false;
		}
	}
	const updateState = (calculation) => {
		if (calculation) {
			setPanData({
				...panData,
				aocode: calculation.areacode,
				ao: calculation.aotype,
				range: calculation.rangecode,
				aonumber: calculation.aonumber
			})
		}
	}
	var array1 = [
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		[1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
		[2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
		[3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
		[4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
		[5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
		[6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
		[7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
		[8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
		[9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
	];

	var array2 = [
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
		[1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
		[5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
		[8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
		[9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
		[4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
		[2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
		[7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
	];
	function invArray(array) {
		if (Object.prototype.toString.call(array) === "[object Number]") {
			array = String(array);
		}
		if (Object.prototype.toString.call(array) === "[object String]") {
			array = array.split("").map(Number);
		}
		return array.reverse();
	}

	function validateAadhaar(array) {
		var c = 0;
		var invertedArray = invArray(array);
		for (var i = 0; i < invertedArray.length; i++) {
			c = array1[c][array2[(i % 8)][invertedArray[i]]];
		}
		return (c === 0);
	}
	const AdharOnBlur = (e) => {
		if (!validateAadhaar(e.target.value)) {
			alert(e.target.value + ' is not a valid AADHAAR number.');
			e.target.value = '';
		} else {
			setPanData({ ...panData, [e.target.name]: e.target.value })
		}

	}
	const startRendSplChar = (x) => {
		if (x.length > 0) {
			var specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
			for (let i = 0; i < specialChars.length; i++) {
				if (x.endsWith(specialChars[i]) || x.startsWith(specialChars[i])) {
					return true;
				}
			}

			var stringArray = ["ABCD", "ASDF", "REJECT", "AADHAAR", "AADHAR", "AGENCY", "ARTS", "ASSOCIATES", "BANK", "BAZAR", "BOOK", "BOOKER", "BUDGET", "CAFÉ", "CAFFE", "CARD", "CARE", "CENTAR", "CENTER", "CENTRE", "CLUB", "COLLEGE", "COMMON", "COMMUNICATION", "COMMUNICATIONS", "COMPANY", "COMPUTER", "COMPUTERS", "COMPUTRONIX", "CONSULTANCY", "CONSULTANTS", "CORNER", "CSC", "CSP", "CYBER", "DAHAGWAN", "DAHGAWAN", "DAILY", "DAIRY", "DIGITAL", "DIGITALS", "ELECTRONICS", "ENTERPRISE", "ENTERPRISES", "FARM", "FOREST", "GALLERY", "GARMENTS", "GLOBAL", "GRAPHICS", "GROUP", "HEALTH", "HELTH", "HITECH", "INDUSTRIES", "INSTITUTE", "INTERNET", "JANSEVA", "JANSUBIDHA", "JANHITSEVA", "JANSEWA", "JANSUVIDHA", "KENDER", "KENDRA", "KIOSK", "LABOUR", "LADIES", "LAND", "LIBRARY", "LIMITED", "LTD", "MANDIR", "MANPOWER", "MARKET", "MEDICAL", "MEESEVA", "MEEMSA", "MEESEVA", "MOBILE", "MONEY", "MULTI", "NSDL", "PAN", "PANCENTRE", "PHOTO", "PHOTOSTATE", "POINT", "PORSA", "PRESS", "PRIVATE", "PUBLIC", "PUBLISHING", "PVT", "RURAL", "SALES", "SCHOOL", "SECURITIES", "SECURITY", "SERVICE", "SERVICES", "SEVA", "SEWA", "SHOP", "SHOPEE", "SHREE", "SKYNET", "SOLUTION", "SOLUTIONS", "STORE", "STUDIO", "SUVIDHA", "SWEET", "TAX", "TECHNOLOGY", "TELECOM", "TRADERS", "TRANSFER", "TRAVEL", "TRAVELS", "TYPE", "WEB", "WORK", "WORLD", "XEROX", "ZEROX"];
			for (let j = 0; j < stringArray.length; j++) {
				if (x.includes(stringArray[j])) {
					return true;
				}
			}

			var stringArray1 = ["C/O", "CO", "M/A", "M/S", "STEEL CITY"];
			for (let k = 0; k < stringArray1.length; k++) {
				if (x.startsWith(stringArray1[k])) {
					return true;
				}
			}

			var stringArray2 = ["ADD", "ADDRESS", "AGA", "HNO", "HNO0", "HNO00", "HNO-0", "H.N.-000", "H.NO.00", "H.NO.0", "H.NO.00", "H.NO-0", "H/N0", "HN0", "HN00", "HN0", "HN-0", "HN00", "HN-00", "HNO", "HNO00", "HOUSE", "HOUSENO", "HOUSENO0", "HOUSENO00", "HOUSENO.", "HOUSENO.-0", "HOUSENO.00", "HOUSENO-0", "HOUSENO-00", "I", "II", "N", "NA", "NH", "N/A", "NA", "NEAR", "NEJ", "NET", "NEW", "NIL", "NILL", "NO", "NO0", "NO00", "NO.0", "NOAN", "NOTAPPLICABLE", "NULL", "O", "OFFICE", "OFFICEOF", "OO", "OOO", "OOOO", "OR", "PKS", "SAI", "X", "XX", "XXX", "XXXX", "ZZ"];
			for (let l = 0; l < stringArray2.length; l++) {
				if (x == stringArray2[l]) {
					return true;
				}
			}

			if (x.length < 2) {
				return true;
			}
		}
		return false;
	}

	const submitHandler = async (e) => {
		e.preventDefault();
		const submitBtn = document.getElementById('submit-btn')
		submitBtn.className = 'd-none'
		const loadingBtn = document.getElementById('loading-btn')
		loadingBtn.className = loadingBtn.className.replace("d-none", "")
		loadingBtn.className = 'btn btn-danger';
		const panFormData = {
			aocode: panData.aocode,
			ao: panData.ao,
			range: panData.range,
			aonumber: panData.aonumber,
			application: panData.application,
			old_pan: panData.old_pan,
			category: panData.category,
			firstname: panData.firstname,
			middlename: panData.middlename,
			lastname: panData.lastname,
			ffirstname: panData.ffirstname,
			fmiddlename: panData.fmiddlename,
			flastname: panData.flastname,
			mfirstname: panData.mfirstname,
			mmiddlename: panData.mmiddlename,
			mlastname: panData.mlastname,
			cardHolder: panData.cardHolder,
			dob: panData.dob,
			contect_number: panData.contect_number,
			email: panData.email,
			proofid: panData.proofid,
			proofadd: panData.proofadd,
			proofdob: panData.proofdob,
			gender: panData.gender,
			adhar_number: panData.adhar_number,
			address_f: panData.address_f,
			address_v: panData.address_v,
			address_p: panData.address_p,
			address_divi: panData.address_divi,
			address_d: panData.address_d,
			state: panData.state,
			applicant: panData.applicant,
			pin_code: panData.pin_code,
		}
		try {
			const token = localStorage.getItem('token');
			// Headers
			var config = {
				headers: {
					'Content-type': 'application/json'
				}
			};
			if (token) {
				config.headers['x-auth-token'] = token;
			}
			const res = await axios.post('/pan/create', panFormData, config)
			if (res.status == 200) {
				submitBtn.className = submitBtn.className.replace("d-none", "");
				submitBtn.className = 'btn btn-danger';
				loadingBtn.className = 'd-none'
				history.push('/mypans');
			}
		} catch (error) {
			console.log(error.message)
			if (error) {
				submitBtn.className = submitBtn.className.replace("d-none", "");
				submitBtn.className = 'btn btn-danger';
				loadingBtn.className = 'd-none'
				setPanData({ msg: error.response.data.msg });
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			} else if (error.response.status == 401) {
				localStorage.removeItem('token')
				history.push('/');
			}
			var newToast = document.getElementById("snackbar");
			newToast.className = "show bg-danger";
			setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
		}
	}
	return (
		<div>
			<div style={ { borderRadius: '15px', padding: '0px', boxShadow: 'none' } }>
				<center>
					<div id="demo">
						<Codes update={ updateState } />
						<form onSubmit={ (e) => submitHandler(e) } autoComplete="off">
							{ panData.msg.length ? panData.msg.map(validmsg => (
								<div key={ validmsg.msg } id='snackbar'>
									<strong>{ validmsg.msg }</strong>
								</div>
							)) : null }
							<table border="0" cellPadding="0" style={ {
								backgroundColor: '#ffff',
								borderBottom: "1px solid #dddfe2",
								borderRadius: '2px 2px 0 0',
								fontWeight: 'bold',
								padding: '8px 6px',
								backgroundColor: '#FFF',
								padding: '30px',
							} }>
								<input type="hidden" value="15" name="dd" readOnly />
								<input type="hidden" value="10" name="mm" readOnly />
								<input type="hidden" value="2021" name="yy" readOnly />
								{/* <tr> */ }
								<td colSpan="2" className="formheading">General Information - </td>

								{/* <tr>
                  <td>Area Officer(AO)</td><td>
                    <input type="text" size="50" name="aoname" placeholder="CITY" id="aoname" value='' readOnly />
                  </td>
                </tr> */}
								<tr>
									<td>AO Code</td>
									<td>
										<table border="0">
											<tbody>
												<tr>
													<td>
														<input type="text" size="10" placeholder="AO Code" onChange={ (e) => changeHandler(e) } name="aocode" id="aocode" readOnly value={ panData.aocode } required />
													</td>
													<td>
														<input type="text" size="10" placeholder="AO Type" onChange={ (e) => changeHandler(e) } name="ao" id="ao" readOnly value={ panData.ao } required />
													</td>
													<td>
														<input type="text" size="10" placeholder="Range Code" onChange={ (e) => changeHandler(e) } name="range" id="range" readOnly value={ panData.range } required />
													</td>
													<td>
														<input type="text" size="10" placeholder="AO Number" onChange={ (e) => changeHandler(e) } name="aonumber" id="aonumber" readOnly value={ panData.aonumber } required />
													</td>
												</tr>

											</tbody>
										</table>
									</td>
								</tr>
								<tr>
									<td>Application type </td>
									<td>
										<select name="application" id="application" value={ panData.application } onChange={ (e) => changeHandler(e) }>
											<option>-select-</option>
											<option value="NEW PAN">New Application</option>
											<option value="CORECATION">Correction/Reprint</option>
											{/* <!-- <option value="3">Re-print</option>--> */ }
										</select>
										<span id="errorApptype"></span>
										<span style={ { visibility: 'hidden' } } id='oldPan'>Old PAN No.<input type="text" className="m" size="20" maxLength="10" name="old_pan" onChange={ (e) => changeHandler(e) } value={ panData.old_pan } id="old_pan" pattern="[A-Za-z]{5}\d{4}[A-Za-z]{1}" /></span>
										<span id="old_pan"></span>
									</td>
								</tr>

								<tr>
									<td>Category of Applicant</td><td>
										<select name="category" onChange={ (e) => changeHandler(e) } value={ panData.category } id="category" required>
											<option>-select-</option>
											<option selected="selected" value="INDIVIDUAL">Individual</option>
										</select>
									</td>
								</tr>
								<tr>
									<td>Applicant's title</td>
									<td>
										<select name="applicant" id="applicant" onChange={ (e) => changeHandler(e) }
											value={ panData.applicant }
											required>
											<option>--Select Type of Application--</option>
											<option value="MR">MR</option>
											<option value="MRS">MRS</option>
											<option value="KUMARI">KUMARI</option>
											{/* <!--<option value="4">M/s</option>--> */ }
										</select>
										<span id="errorApptitle"></span>
									</td>
								</tr>
								<tr>
									<td>Applicant Name</td>
									<td>
										<input type="text" id="firstname" name="firstname" placeholder="First Name" autoComplete="off" value={ panData.firstname } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." />
										<input type="text" id="middlename" name="middlename" placeholder="Middle Name" autoComplete="off" value={ panData.middlename } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." />
										<input type="text" id="lastname" name="lastname" placeholder="Last Name" autoComplete="off" value={ panData.lastname } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." required />
									</td>
								</tr>
								<tr>
									<td>Father's Name</td>
									<td>
										<input type="text" className="m" id="ffirstname" name="ffirstname" placeholder="First Name" autoComplete="off" value={ panData.ffirstname } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." />
										<input type="text" className="m" id="fmiddlename" name="fmiddlename" placeholder="Middle Name" autoComplete="off" value={ panData.fmiddlename } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." />
										<input type="text" className="m" id="flastname" name="flastname" placeholder="Last Name" autoComplete="off" value={ panData.flastname } onChange={ (e) => changeHandler(e) } title="Minimum 2 letters(A-Z) only." required />
									</td>
								</tr>
								<tr>
									<td>Name on Card</td>
									<td>
										<input type="text" size="75" value={ `${panData.firstname} ${panData.middlename} ${panData.lastname}` } onChange={ (e) => changeHandler(e) } className="m" id="cardHolder" name="cardHolder" placeholder="Name on Card" autoComplete="off" readOnly required />
									</td>
								</tr>

								<tr>
									<td>Date of Birth</td>
									<td>
										<input type="text" onChange={ (e) => changeHandler(e) } value={ panData.dob } className="m" name="dob" id="dob" placeholder='DD/MM/YYYY' autoComplete="off" title="Two numaric digits of day." required />
										{/* <input type="text" onChange={(this) =>checkDDNo(this, 1, 12)} className="m" name="dobmm" id="dobmm" size="2" maxLength="2" placeholder="MM" autoComplete="off" pattern="^\d{2}$" title="Two numaric digits of month." required />/
                    <input type="text" onChange="checkDDNo(this, currentDate.getFullYear() - 100, currentDate.getFullYear());" className="m" name="dobyy" id="dobyy" size="4" maxLength="4" placeholder="YYYY" autoComplete="off" pattern="^\d{4}$" title="Four numaric digits of year." required />
                    <span id="dobyears" style={{ paddingLeft: '50px' }}></span>
                    <input type="hidden" name="isminor" id="isminor" value="n" /> */}
									</td>
								</tr>
								<tr>
									<td colSpan="2"><span id="dobmsg" style={ { backgroundColor: '#000', color: "white" } }></span></td>
								</tr>
								<tr>
									<td>Contact Details</td>
									<td>
										<input type="text" onChange={ (e) => changeHandler(e) } value={ panData.contect_number } name="contect_number" id="contect_number" placeholder="Mobile Number" size="10" maxLength="10" autoComplete="off" title="Ten numaric digits of mobile number only." required />
									</td>
								</tr>
								<tr>
									<td>E-mail ID</td>
									<td>
										<input type="email" onChange={ (e) => changeHandler(e) } value={ panData.email } name="email" id="email" autoComplete="off" placeholder="Email-ID" title="xyz@something.com" required />
									</td>
								</tr>
								<tr>
									<td>
										Gender
									</td>
									<td>
										<input type="text" className="m" name="gender" id="gender" value={ panData.applicant === "MR" ? "MALE" : "FEMALE" } onChange={ (e) => changeHandler(e) } required />
									</td>
								</tr>
								<tr>
									<td>
										Aadhar No.
									</td>
									<td>
										<input type="text" onChange={ (e) => changeHandler(e) } value={ panData.adhar_number } onBlur={ (e) => AdharOnBlur(e) } className="m" name="adhar_number" id="adhar_number" size="12" maxLength="12" placeholder="12 digit AADHAR" autoComplete="off" title="12 numeric characters of AADHAAR." required />
									</td>
								</tr>
								<tr>
									<td colSpan="2" className="formheading">Address Details -</td>
								</tr>
								<tr>
									<td>Father/Husband/Guardian Name: </td>
									<td>
										<input type="text" onBlur={ (e) => {
											if (startRendSplChar(e.target.value.toUpperCase())) {
												alert(e.target.value + '\nis not a valid address line.');
												e.target.value = '';
												e.target.focus();
											}
										} } onChange={ (e) => changeHandler(e) } value={ panData.address_f } name="address_f" id="address_f" className="m" size="65" maxLength="24" autoComplete="off" required />
										<span id="not1" className="note"></span>
									</td>
								</tr>
								<tr>
									<td>Premises/Building/Village: </td>
									<td>
										<input type="text" onBlur={ (e) => {
											if (startRendSplChar(e.target.value.toUpperCase())) {
												alert(e.target.value + '\nis not a valid address line.');
												e.target.value = '';
												e.target.focus();
											}
										} } onChange={ (e) => changeHandler(e) } value={ panData.address_v } name="address_v" id="address_v" className="m" size="65" maxLength="24" autoComplete="off" required />
										<span id="not2" className="note"></span>
									</td>
								</tr>
								<tr>
									<td>Road/Street/Lane/Post Office: </td>
									<td>
										<input type="text" onBlur={ (e) => {
											if (startRendSplChar(e.target.value.toUpperCase())) {
												alert(e.target.value + '\nis not a valid address line.');
												e.target.value = '';
												e.target.focus();
											}
										} } onChange={ (e) => changeHandler(e) } value={ panData.address_p } name="address_p" id="address_p" className="m" size="65" maxLength="24" autoComplete="off" required />
										<span id="not3" className="note"></span>
									</td>
								</tr>
								<tr>
									<td>Area/Taluka/Sub-division: </td>
									<td>
										<input type="text" onBlur={ (e) => {
											if (startRendSplChar(e.target.value.toUpperCase())) {
												alert(e.target.value + '\nis not a valid address line.');
												e.target.value = '';
												e.target.focus();
											}
										} } onChange={ (e) => changeHandler(e) } value={ panData.address_divi } name="address_divi" id="address_divi" className="m" size="65" maxLength="24" autoComplete="off" />
										<span id="not4" className="note"></span>
									</td>
								</tr>
								<tr>
									<td>Town/District: </td>
									<td>
										<input type="text" onBlur={ (e) => {
											if (startRendSplChar(e.target.value.toUpperCase())) {
												alert(e.target.value + '\nis not a valid address line.');
												e.target.value = '';
												e.target.focus();
											}
										} } onChange={ (e) => changeHandler(e) } value={ panData.address_d } name="address_d" id="address_d" className="m" size="65" maxLength="24" autoComplete="off" required />
										<span id="not5" className="note"></span>
									</td>
								</tr>
								<tr>
									<td>State/Union Territory: </td>
									<td>
										<select name="state" id="state" onChange={ (e) => changeHandler(e) } value={ panData.state }>
											<option>Please Select</option>
											<option value="ANDAMAN AND NICOBAR ISLANDS">ANDAMAN AND NICOBAR ISLANDS</option>
											<option value="ANDHRA PRADESH">ANDHRA PRADESH</option>
											<option value="ARUNACHAL PRADESH">ARUNACHAL PRADESH</option>
											<option value="ASSAM">ASSAM</option>
											<option value="BIHAR">BIHAR</option>
											<option value="CHANDIGARH">CHANDIGARH</option>
											<option value="CHHATTISGARH">CHHATISHGARH</option>
											<option value="DADRA & NAGAR HAVELI">DADRA &amp; NAGAR HAVELI</option>
											<option value="DAMAN & DIU">DAMAN &amp; DIU</option>
											<option value="DELHI">DELHI</option>
											<option value="GOA">GOA</option>
											<option value="GUJARAT">GUJARAT</option>
											<option value="HARYANA">HARYANA</option>
											<option value="HIMACHAL PRADESH">HIMACHAL PRADESH</option>
											<option value="JAMMU AND KASHMIR">JAMMU &amp; KASHMIR</option>
											<option value="JHARKHAND">JHARKHAND</option>
											<option value="KARNATAKA">KARNATAKA</option>
											<option value="KERALA">KERALA</option>
											<option value="LADAKH">LADAKH</option>
											<option value="LAKHSWADEEP">LAKHSWADEEP</option>
											<option value="MADHYA PRADESH">MADHYA PRADESH</option>
											<option value="MAHARASHTRA">MAHARASHTRA</option>
											<option value="MANIPUR">MANIPUR</option>
											<option value="MEGHALAYA">MEGHALAYA</option>
											<option value="MIZORAM">MIZORAM</option>
											<option value="NAGALAND">NAGALAND</option>
											<option value="ODISHA">ODISHA</option>
											<option value="OUTSIDE INDIA">OUTSIDE INDIA</option>
											<option value="PONDICHERRY">PONDICHERRY</option>
											<option value="PUNJAB">PUNJAB</option>
											<option value="RAJASTHAN">RAJASTHAN</option>
											<option value="SIKKIM">SIKKIM</option>
											<option value="TAMIL NADU">TAMILNADU</option>
											<option value="TELANGANA">TELANGANA</option>
											<option value="TRIPURA">TRIPURA</option>
											<option value="UTTAR PRADESH">UTTAR PRADESH</option>
											<option value="UTTARAKHAND">UTTARAKHAND</option>
											<option value="WEST BENGAL">WEST BENGAL</option>
										</select>

									</td>
								</tr>
								<tr>
									<td>PIN</td>
									<td>
										<input type="text" onChange={ (e) => changeHandler(e) } value={ panData.pin_code } name="pin_code" id="pin_code" size="10" maxLength="6" autoComplete="off" title="Six numaric digits of PIN." required />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<center>
											<div className="singh mt-3">
												<button id='submit-btn' className="btn btn-danger">
													Submit
												</button>
												<button id='loading-btn' class="btn d-none btn-danger" type="button" disabled>
													<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
													Loading...
												</button>
											</div>
										</center>
									</td>
								</tr>

							</table>
						</form>


					</div>

				</center>
			</div>
		</div>
	)
}

export default Newpan
