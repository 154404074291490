import React, { Component, useEffect, useState } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import Loader from '../Loader';
import { useHistory } from 'react-router-dom';


const MyPaymentHistory = () => {
    const history = useHistory();
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [msg, setMsg] = useState([]);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        setSkip(skip + limit)
        console.log(skip)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    useEffect(() => {
        getPaymentHistory(limit, skip);
    }, [limit, skip])

    const getPaymentHistory = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('token')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const paymentsHistory = await axios.get(`/pan/mypaymenthistory/user?limit=${limit}&skip=${skip}`, config);
            setPaymentHistory(paymentsHistory.data)
            setLoader(false)
        } catch (error) {
            console.log(error.response.status);
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                history.push('/')
            }
        }
    }

    return (
        <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div className="container-fluid">
                <div className="card shadow-lg">
                    <div className="card-header bg-dark">
                        <h3 className="text-center text-light">Payments History</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id='myTable' className="table table-bordered shadow table-hover text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Totle Amount</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Apply Date</th>
                                    </tr>
                                </thead>
                                {paymentHistory.length ? paymentHistory.map((pan, index) =>
                                    <tbody key={index}>
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{pan.amount}</td>
                                            <td>{pan.totleBalance}</td>
                                            <td>{pan.status}</td>
                                            <td>
                                                <Moment format='lll'>
                                                    {pan.date}
                                                </Moment>
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : null}
                            </table>
                            {loader ? <Loader /> : null}
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a onClick={previousPage} className="page-link" href="#">Previous</a>
                                </li>
                                <li className="page-item">
                                    <div onClick={nextPage} className="page-link">Next</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MyPaymentHistory
