import React, {Component} from 'react'
import axios from 'axios';
import Moment from 'react-moment';



export class Profile extends Component {
    constructor(props) {
        super(props);
        let loggedIn=false
        this.state={
            userData: {},
            msg: {},
            loggedIn
        }
    }

    async componentDidMount() {
        try {

            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };

            if (token) {
                config.headers['x-auth-token']=token;
            }

            await axios.get(`/api/userProfile`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            // console.log(error.response.status);
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }
    changeHandel=(e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    profileUpdate=async (e) => {
        e.preventDefault();
        try {
            const user={
                fullname: this.state.fullname,
                shopname: this.state.shopname,
                email: this.state.email,
                address: this.state.address,
                pannumber: this.state.pannumber,
                aadharnumber: this.state.aadharnumber,
            }
            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "Application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token']=token;
            }
            const res=await axios.put(`/user/profile/update/${this.state._id}`, user, config);
            if (res.data) {
                this.setState({msg: res.data.msg})
                var newToast=document.getElementById("snackbar");
                newToast.className="show bg-success";
                setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
            }
        } catch (error) {
            console.log(error.response.status);
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            } else if (error.response.data.msg) {
                this.setState({msg: error.response.data.msg})
                var x=document.getElementById("snackbar");
                x.className="show bg-success";
                setTimeout(function() {x.className=x.className.replace("show", "");}, 3000);
            }
        }
    }

    render() {

        const {msg}=this.state;
        return (
            <div >
                <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <div className="container mb-5" style={{paddingTop: '50px'}}>
                        <div className="card shadow-sm" >
                            <div className="card-header">
                                {msg.length? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>):null}
                                <h4 className="text text-center text-light">Welcome {this.state.name}</h4>
                                <center>
                                    <img style={{width: "300px", borderRadius: "50%", border: '2px solid #fff'}} src='/images/img_avatar.png' alt="" />
                                </center>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.profileUpdate}>
                                    <table className="table table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>User Name</td>
                                                <td>{this.state.username}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Name</td>
                                                <td>
                                                    <input type="text" className='form-control form-control-sm' name='fullname' value={this.state.fullname} onChange={this.changeHandel} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Email</td>
                                                <td>
                                                    <input type="text" className='form-control form-control-sm' name='email' value={this.state.email} onChange={this.changeHandel} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <td>Mobile</td>
                                                <td>{this.state.mobile}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">5</th>
                                                <td>Pan number</td>
                                                <td>
                                                    <input type="text" className='form-control form-control-sm' name='pannumber' value={this.state.pannumber} onChange={this.changeHandel} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">6</th>
                                                <td>Aadhar number</td>
                                                <td>
                                                    <input type="text" className='form-control form-control-sm' name='aadharnumber' value={this.state.aadharnumber} onChange={this.changeHandel} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">7</th>
                                                <td>Address</td>
                                                <td>
                                                    <input type="text" className='form-control form-control-sm' name='address' value={this.state.address} onChange={this.changeHandel} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">8</th>
                                                <td>Wallet Amount</td>
                                                {this.state.totleAmount?
                                                    <td>{this.state.totleAmount}</td>:<td> 0 Rs.</td>
                                                }
                                            </tr>
                                            <tr>
                                                <th scope="row">9</th>
                                                <td>Registered Date</td>
                                                <td>
                                                    <Moment format='lll'>
                                                        {this.state.date}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button className='btn btn-success'>Update Profile</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default Profile
