import React, {Component} from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'

export class userDashboard extends Component {
    constructor(props) {
        super(props);
        let loggedIn=false
        this.state={
            userData: {},
            myPanData: 0,
            paymentData: 0,
            msg: {},
            loggedIn
        }
    }

    async componentDidMount() {
        try {

            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };

            if (token) {
                config.headers['x-auth-token']=token;
            }

            await axios.get(`/api/userProfile`, config)
                .then(res => {
                    this.setState({userData: res.data})
                })
            const mypandata=await axios.get(`/pan/user/total/pan/count`, config);
            this.setState({myPanData: mypandata.data});
            const totalPayment=await axios.get(`/api/total/payments/count/foruser`, config);
            this.setState({paymentData: totalPayment.data})
        } catch (error) {
            // console.log(error.response.status);
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }

    render() {

        const {userData, myPanData, paymentData}=this.state;
        return (
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="m-auto" style={{paddingTop: '50px'}}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <Link className="nav-link" to='/Profile'>
                                                <div className="card-body">
                                                    <p className="text-center"><i className="fa fa-money fa-3x text-light" aria-hidden="true"></i></p>
                                                    <h3 className="text-center text-light">
                                                        Wallet
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="text-center text-light">
                                                        <i className="fa fa-inr text-light" aria-hidden="true"></i> &nbsp;
                                                        {userData.totleAmount}
                                                    </h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <Link className="nav-link" to="/NewPan">
                                                <div className="card-body">
                                                    <p className="text-center">
                                                        <i className="fa fa-id-card fa-3x text-light" aria-hidden="true"></i>
                                                    </p>
                                                    <h3 className="text-center text-light">Apply Pan card</h3>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="text-center text-light">{myPanData}</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <Link className="nav-link" to='/mypayments'>
                                                <div className="card-body">
                                                    <p className="text-center"><i className="fa fa-money fa-3x text-light" aria-hidden="true"></i></p>
                                                    <h3 className="text-center text-light">
                                                        Payments
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="text-center text-light">{paymentData}</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <a className="nav-link" target='true' href='https://www1.incometaxindiaefiling.gov.in/e-FilingGS/Services/LinkAadhaarHome.html?lang=eng'>
                                                <div className="card-body">
                                                    <p className="text-center"><i className="fa fa-money fa-3x text-light" aria-hidden="true"></i></p>
                                                    <h3 className="text-center text-light">
                                                        Link Pan to Aadhar
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    {/* <h3 className="text-center text-light">{ paymentData.length }</h3> */}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <a className="nav-link" target='true' href='https://b2bparasnet.com/'>
                                                <div className="card-body">
                                                    <p className="text-center"><i className="fa fa-money fa-3x text-light" aria-hidden="true"></i></p>
                                                    <h3 className="text-center text-light">
                                                        AEPS DMT etc
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    {/* <h3 className="text-center text-light">{ paymentData.length }</h3> */}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card my-2 bg-info shadow text-light">
                                            <a className="nav-link" target='true' href='https://eaadhaar.uidai.gov.in/#/'>
                                                <div className="card-body">
                                                    <p className="text-center"><i className="fa fa-money fa-3x text-light" aria-hidden="true"></i></p>
                                                    <h3 className="text-light text-center">
                                                        Aadhar Download
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    {/* <h3 className="text-center text-light">{ paymentData.length }</h3> */}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default userDashboard
