import React, { Component } from 'react'
import './Header.css'
import AdminSideNav from './AdminSideNav'
import UserSideNav from './UserSideNav'
import { Link, withRouter } from 'react-router-dom';


export class Header extends Component {


  adminLogout(e) {
    e.preventDefault();
    localStorage.removeItem('tokens');
    this.props.history.push('/Admin');
  }

  render() {



    var tokens = localStorage.getItem('tokens')
    if (tokens) {
      return (
        <div>
          <header class="navbar navbar-dark shadow fixed-top bg-dark flex-md-nowrap p-0 shadow">
            <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">PARA NET</a>
            <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
            <ul class="navbar-nav px-3">
              <li class="nav-item text-nowrap">
                <a class="nav-link" onClick={this.adminLogout.bind(this)} href="#">Sign out</a>
              </li>
            </ul>
          </header>
          <AdminSideNav />
        </div>
      )
    }
    const beforeLogin = (
      <div>
        <header className="clientheader">
          <nav class="navbar navbar-expand-md navbar-light fixed-top bg-white shadow p-3 mb-5">
            <div class="container-fluid">
              <img src="pnlogo.png" alt="pnlogo" style={{ height: '80px', width: '100px' }} />
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto mb-2 mb-md-0">
                  <li class="nav-item">
                    <Link class="nav-link nav-button" aria-current="page" to="/">Home</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link nav-button" aria-current="page" to="/about">About</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link nav-button" aria-current="page" to="/service">Services</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link nav-button" aria-current="page" to="/contact">Contact</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link nav-button" aria-current="page" to="/login">Login</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    )
    const afterLogin = (
      <div>
        <header class="navbar navbar-dark shadow-sm fixed-top bg-dark flex-md-nowrap p-0 shadow">
          <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#">PARAS NET</a>
          <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
          {/* <ul class="navbar-nav px-3">
            <li class="nav-item text-nowrap">
              <Link class="nav-link" onClick={this.logOut.bind(this)} href="#">Sign out</Link>
            </li>
          </ul> */}
        </header>
        <UserSideNav />
      </div>
    )
    return (
      <div>
        { localStorage.token ? afterLogin : beforeLogin}
      </div>
    )
  }
}

export default withRouter(Header)
