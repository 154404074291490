import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';
import UploadSlip from './UploadSlip';


const CompletePan = () => {
    const [pans, setPans] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loggedIn, setLoggedIn] = useState(true);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const nextPage = () => {
        setSkip(skip + limit)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const getData = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/pan/pans?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    setPans(res.data);
                    setLoader(false);
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                setLoggedIn(false)
                localStorage.removeItem('token')
                history.push('/Admin')
            }
        }
    }
    useEffect(() => {
        getData(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="card mb-5">
                        <div className="card-header bg-dark">
                            <h4 className="text text-center text-light">Complete Pans</h4>
                        </div>
                        <div className="card-body shadow">
                            <div className="table-responsive">
                                <table id='myTable' className="table shadow table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Pan Apply User</th>
                                            <th scope="col">Pan Holder</th>
                                            <th scope="col">Client Slip</th>
                                            <th scope="col">Nsdl Slip</th>
                                            <th scope="col">Pan Status</th>
                                            <th scope="col">Upload Slip</th>
                                            <th scope="col">Apply Date</th>
                                            <th scope="col">Update Date</th>
                                        </tr>
                                    </thead>
                                    {
                                        pans.length ? pans.map((pan, index) => {
                                            return <tbody key={ index }>
                                                <tr>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>
                                                        &nbsp; { pan.username }
                                                    </td>
                                                    <td>
                                                        <Link className="nav-link" target='_blank' to={ `/update/${pan._id}` }>
                                                            { pan.cardHolder }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link className='nav-link' target="true" to={ `/panfiles/${pan.slip}` }>
                                                            { pan.acknowledgement }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link className='nav-link' target="true" to={ `/fornsdl/${pan.slip}` }>
                                                            { pan.acknowledgement }
                                                        </Link>
                                                    </td>
                                                    <td className={ pan.panStatus == 'FILE REUPLOAD' ? 'bg-warning' : 'bg-success' }>
                                                        { pan.panStatus }&nbsp; { pan.reasonOfReject }
                                                    </td>
                                                    <td >
                                                        <UploadSlip userId={ pan._id } />
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            { pan.createdAt }
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            { pan.updatedAt }
                                                        </Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }) :
                                            null
                                    }
                                </table>
                                { loader ? <Loader /> : null }
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={ previousPage } className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={ nextPage } className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )

}

export default CompletePan
