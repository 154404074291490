import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';

const Allpayments = () => {
    const history = useHistory();
    const [allPayments, setAllpayments] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [loader, setLoader] = useState(false);

    const nextPage = () => {
        setSkip(skip + limit)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }

    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const getAllpayments = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/api/allPayments?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    const reverseData = res.data;
                    setAllpayments(reverseData)
                    setLoader(false)
                    // console.log(reverseData[0].paymentBy._id);
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                setLoggedIn(false)
                localStorage.removeItem('tokens')
                history.push('/Admin')
            }
        }
    }

    useEffect(() => {
        getAllpayments(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="card shadow">
                        <div className="card-header bg-dark">
                            <h3 className="text text-center text-light">All Payments</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id='myTable' className="table table-bordered shadow table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Domestic Member</th>
                                            <th scope="col" className="text-center">Bank Name</th>
                                            <th scope="col" className="text-center">Tranfer Refrence code</th>
                                            <th scope="col" className="text-center">Payment Date</th>
                                            <th scope="col" className="text-center">Payment</th>
                                            <th scope="col" className="text-center">Accept / Reject</th>
                                        </tr>
                                    </thead>
                                    { allPayments.length ? allPayments.map((payments, index) => (
                                        <tbody key={ index }>
                                            <tr>
                                                <th scope="row">
                                                    <Link className="nav-link text-primary" to={ { pathname: `/admin/paymentbyuserid`, state: payments.paymentBy } }>
                                                        { index + 1 }
                                                    </Link>
                                                </th>
                                                <td>
                                                    <Link className="nav-link text-primary" to={ { pathname: `/admin/paymenthistory`, state: payments.paymentBy } }>
                                                        { payments.username }
                                                    </Link>
                                                </td>
                                                <td className="text-center">
                                                    { payments.bankName }
                                                </td>
                                                <td className="text-center">{ payments.tranferRefCode } </td>
                                                <td className="text-center">
                                                    <Moment format='lll'>
                                                        { payments.date }
                                                    </Moment>
                                                </td>
                                                <td className="text-center">
                                                    { payments.totleAmount }
                                                </td>
                                                { payments.acceptStatus === 'Accepted' ?
                                                    <td className="text-center text-light bg-success">
                                                        { payments.acceptStatus }
                                                    </td> : null }
                                                { payments.acceptStatus === 'Rejected' ? <td className="text-center text-light bg-danger">
                                                    { payments.acceptStatus },
                                                    <span className="text-center"> { payments.reasonOfReject } </span>
                                                </td> : null }
                                                {
                                                    !payments.acceptStatus ? <td className="text-center text-light bg-secondary">
                                                        <Link className="nav-link text-light" user={ payments.paymentBy } to={ `/acceptPayment/${payments._id}` }>
                                                            Pending
                                                        </Link>
                                                    </td> : null }
                                            </tr>
                                        </tbody>
                                    )) : null
                                    }
                                </table>
                                { loader ? <center>
                                    <Loader />
                                </center> : null }
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={ previousPage } className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={ nextPage } className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Allpayments
