import React, {Component} from 'react';
import axios from 'axios';

export class userPayment extends Component {

    constructor(props) {
        super(props);
        this.state={
            bankName: '',
            tranferRefCode: '',
            totleAmount: '',
            bankdata: {},
            msg: {}
        }

        this.changeHandle=this.changeHandle.bind(this);
        this.submitHandle=this.submitHandle.bind(this);
    }

    changeHandle=e => {
        this.setState({[e.target.name]: e.target.value})
    }

    submitHandle=async e => {
        e.preventDefault();
        const submitBtn=document.getElementById('submit-btn')
        submitBtn.className='d-none'
        const loadingBtn=document.getElementById('loading-btn')
        loadingBtn.className=loadingBtn.className.replace("d-none", "")
        loadingBtn.className='btn btn-danger';
        const newPayment={
            bankName: this.state.bankName,
            tranferRefCode: this.state.tranferRefCode,
            totleAmount: this.state.totleAmount
        }

        try {
            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "Application/json"
                }
            };

            if (token) {
                config.headers['x-auth-token']=token;
            }
            const res=await axios.post('/api/payment/add', newPayment, config);
            this.setState({msg: res.data.msg, token: res.data, bankName: '', tranferRefCode: '', totleAmount: ''})
            if (res.data) {
                submitBtn.className=submitBtn.className.replace("d-none", "");
                submitBtn.className='btn btn-danger';
                loadingBtn.className='d-none'
                var newToast=document.getElementById("snackbar");
                newToast.className="show bg-success";
                setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
            } else {
                this.props.history.push('/mypayments');
                this.props.history.push('/register');
            }
        } catch (error) {
            if (error.response.data.msg) {
                submitBtn.className=submitBtn.className.replace("d-none", "");
                submitBtn.className='btn btn-danger';
                loadingBtn.className='d-none'
                this.setState({msg: error.response.data.msg})
            } else {
                this.setState({msg: error.response.data.errors})
            }
            var newToast=document.getElementById("snackbar");
            newToast.className="show bg-danger";
            setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);

        }
    }

    async componentDidMount() {
        try {

            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token']=token;
            }
            await axios.get(`/api/new/bank`, config)
                .then(res => {
                    this.setState({bankdata: res.data})
                })
        } catch (error) {
            console.log(error.response.status);
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }

    render() {

        const {msg, bankdata}=this.state;
        return (
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container" style={{paddingTop: '50px'}}>
                    <div className="card shadow-sm">
                        <div className="card-header bg-dark text-light">
                            <h3 className="text text-center">Payment</h3>
                            <hr />
                        </div>
                        <div className="table-responsive">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Bank Account N.</th>
                                        <th scope="col">Bank Name</th>
                                        <th scope="col">Bank IFSC Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bankdata.accountnumber}</td>
                                        <td>{bankdata.bankname}</td>
                                        <td>{bankdata.ifsccode}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.submitHandle}>
                                {msg.length? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>):null}
                                <div className="form-group">
                                    <label className="col-form-label" htmlFor="band-details">Bank Details</label>
                                    <input type="text" className="form-control form-control-sm" onChange={this.changeHandle} name="bankName" value={this.state.bankName} placeholder="Enter bank name" />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label" htmlFor="request-refrence">Request Refrence Code</label>
                                    <input type="text" className="form-control form-control-sm" onChange={this.changeHandle} value={this.state.tranferRefCode} name="tranferRefCode" placeholder="Enter transfer refrecne code" />
                                </div>
                                <div className="form-group">
                                    <label className="col-form-label" htmlFor="totleAmount">Amout</label>
                                    <input type="text" className="form-control form-control-sm" onChange={this.changeHandle} value={this.state.totleAmount} name="totleAmount" placeholder="Enter Request totleAmount" />
                                </div>
                                <button id='submit-btn' className="btn mt-3 btn-danger">Submit</button>
                                <button id='loading-btn' class="btn d-none btn-danger" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											Loading...
										</button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default userPayment
