import React, { Component } from 'react'
import axios from 'axios';
import { Redirect } from 'react-router-dom';

export class UploadSlip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slip: '',
            slipname: 'Choose Image file',
            msg: {}
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.changeHandle = this.changeHandle.bind(this)
        this.changeSlip = this.changeSlip.bind(this);
    }

    changeSlip = (e) => {
        this.setState({ slip: e.target.files[0] });
        this.setState({ slipname: e.target.files[0].name });
    };

    changeHandle = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = async (e) => {
        e.preventDefault();
        try {

            const formData = new FormData();
            formData.append('slip', this.state.slip);

            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            const res = await axios.put(`/pan/updateforslip/${this.props.userId}`, formData, config);
            if (res.status === 200) {
                this.setState({ msg: res.data.msg, token: res.data, slip: '', slipname: '' });
                var newToast = document.getElementById("snackbar");
                newToast.className = "show bg-success";
                setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
            }
            console.log(res.data);
        } catch (error) {
            if (error.response) {
                this.setState({ msg: error.response.data.msg });
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-danger";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
        }
    };

    render() {
        const { msg } = this.state;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    {msg.length ? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>) : null}
                    <div className="form-group mt-3">
                        <input
                            type="file"
                            name="slip"
                            id="customFile"
                            accept="application/pdf"
                            onChange={this.changeSlip}
                        />
                        <input type="submit" value="Upload Slip" className="btn btn-secondary btn-sm" />
                    </div>
                </form>
            </div>
        )
    }
}

export default UploadSlip
