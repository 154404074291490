import React, {Component} from 'react'
import axios from 'axios';
import Moment from 'react-moment';

export class Profile extends Component {
    constructor(props) {
        super(props)

        this.state={
            data: {},
            msg: {}
        }
    }
    async componentDidMount() {
        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            await axios.get(`/api/admin/profile`, config)
                .then(res => {
                    this.setState({data: res.data})
                })
        } catch (error) {
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('tokens')
                this.props.history.push('/Admin')
            }
        }
    }
    changeHandle=async (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    submit=async (e) => {
        e.preventDefault();
        try {
            const data={
                password: this.state.password
            }
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            await axios.put(`/api/admin/password/reset/${this.state.data._id}`, data, config)
                .then(res => {
                    this.setState({password: '', msg: res.data.msg})
                    var newToast=document.getElementById("snackbar");
                    newToast.className="show bg-success";
                    setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
                })
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({msg: error.response.data.msg})
            } else {
                this.setState({msg: error.response.data.errors})
            }
            var newToast=document.getElementById("snackbar");
            newToast.className="show bg-danger";
            setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);

        }
    }

    render() {
        const {data, msg}=this.state;
        return (
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                {msg.length? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>):null}
                <div className="container">
                    <div className="card shadow-sm">
                        <div className="card-header bg-dark text-light">
                            <h4 className="text-center">Admin Profile</h4>
                        </div>
                        <div className="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. N.</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">1</th>
                                        <td>{data.username}</td>
                                        <td>{
                                            <Moment format='lll'>
                                                {data.date}
                                            </Moment>
                                        }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card  shadow-sm">
                                <div className="card-header text-light bg-dark">
                                    <h3 className="text-center">Password Reset</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submit}>
                                        <div className="form-field">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className='form-control form-control-sm' name="password" id="password" onChange={this.changeHandle} value={this.state.password} />
                                        </div>
                                        <button className="btn mt-2 btn-dark">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default Profile
