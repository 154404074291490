import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'

export class AdminSideNav extends Component {
  render() {
    var header=document.getElementsByTagName('li')
    for (var i=0;i<header.length;i++) {
      header[i].addEventListener("click", function() {
        var current=document.getElementsByClassName("active");
        current[0].className=current[0].className.replace(" active", "");
        this.className+=" active";
      });
    }
    return (
      <div>
        <div class="container-fluid">
          <div class="row">
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
              <div class="position-sticky pt-3">
                <ul class="nav flex-column">
                  <li class="nav-item">
                    <Link class="nav-link active" aria-current="page" to="/admindashboard">
                      <i class="material-icons align-bottom">dashboard &nbsp;</i>
                        Dashboard
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/allusers">
                      <i class="material-icons align-bottom">people &nbsp;</i>
                      Users
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/admin/profile">
                      <i class="material-icons align-bottom">account_circle &nbsp;</i>
                      Admin Profile
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/userAllPan">
                      <i class="material-icons align-bottom">credit_card &nbsp;</i>
                      Pans
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/payments">
                      <i class="material-icons align-bottom">credit_card &nbsp;</i>
                      Payments
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/addbank">
                      <i class="material-icons align-bottom">account_balance &nbsp;</i>
                      Add bank
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/codes">
                      <i class="material-icons align-bottom">code &nbsp;</i>
                        Area Codes
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/verifypan">
                      <i class="material-icons align-bottom">credit_card &nbsp;</i>
                      Verifypan
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/admin/reupload">
                      <i class="material-icons align-bottom">credit_card &nbsp;</i>
                      Reupload Data
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/completepan">
                      <i class="material-icons align-bottom">credit_card &nbsp;</i>
                      Completed Pan
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/admin/setting">
                      <i class="material-icons align-bottom">settings &nbsp;</i>
                      Setting
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>

            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
              <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 class="h2">Admin Dashboard</h1>
              </div>
              {/* <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas> */}
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export default AdminSideNav
