import React, {Component, Fragment} from 'react'
// import { Link } from 'react-router-dom'
import axios from 'axios';
import {Link} from 'react-router-dom';


class panById extends Component {
    constructor(props) {
        super(props);
        this.state={
            areaoffice: '',
            aocode: '',
            ao: '',
            range: '',
            aonumber: '',
            application: '',
            old_pan: '',
            category: '',
            applicant: '',
            firstname: '',
            middlename: '',
            lastname: '',
            ffirstname: '',
            fmiddlename: '',
            flastname: '',
            mfirstname: '',
            mmiddlename: '',
            mlastname: '',
            cardHolder: '',
            dob: '',
            contect_number: '',
            email: '',
            proofid: '',
            proofadd: '',
            proofdob: '',
            gender: '',
            adhar_number: '',
            address_f: '',
            address_v: '',
            address_p: '',
            address_divi: '',
            address_d: '',
            state: '',
            pin_code: '',
            parents: '',
            reasonOfReject: '',
            panStatus: '',
            imagename: 'Choose Image file',
            image: '',
            sig: '',
            signame: 'Choose Signature file',
            pdf: '',
            pdfname: 'Choose Document File',
            token: null,
            invalidImage: '',
            invalidSig: '',
            invalidPdf: '',
            msg: {}
        };

        this.changeHandler=this.changeHandler.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.changePhoto=this.changePhoto.bind(this);
        this.changeSig=this.changeSig.bind(this);
        this.changePdf=this.changePdf.bind(this);
    }

    changeHandler=(e) => {
        this.setState({[e.target.name]: e.target.value.toUpperCase()});
        e.preventDefault();
    };

    changePhoto=(e) => {
        this.setState({image: e.target.files[0]});
        this.setState({imagename: e.target.files[0].name});

        const imageType=e.target.files[0].type
        const imageSize=e.target.files[0].size
        if (imageType!=='image/jpg'&&imageType!=='image/JPG'&&imageType!=='image/JPEG'&&imageType!=='image/jpeg') {
            this.setState({invalidImage: 'Only jpg or jpeg image allow'})
            this.setState({imagename: ''})
            this.setState({image: ''})
            return false
        } else {
            this.setState({invalidImage: ''})
        }

        if (imageSize>9000) {
            this.setState({invalidImage: 'Image file Max 5kb'})
            this.setState({imagename: ''})
            this.setState({image: ''})
            return false
        } else {
            this.setState({invalidImage: ''})
        }
    };

    changeSig=(e) => {
        this.setState({sig: e.target.files[0]});
        this.setState({signame: e.target.files[0].name});
        const sigType=e.target.files[0].type
        const sigSize=e.target.files[0].size
        if (sigType!=='image/jpg'&&sigType!=='image/JPG'&&sigType!=='image/JPEG'&&sigType!=='image/jpeg') {
            this.setState({invalidSig: 'Only jpg or jpeg image allow'})
            this.setState({signame: ''})
            this.setState({sig: ''})
            return false
        } else {
            this.setState({invalidSig: ''})
        };


        if (sigSize>9000) {
            this.setState({invalidSig: 'Sig file Max 5kb'})
            this.setState({signame: ''})
            this.setState({sig: ''})
            return false
        } else {
            this.setState({invalidSig: ''})
        }
    };
    changePdf=(e) => {
        this.setState({pdf: e.target.files[0]});
        this.setState({pdfname: e.target.files[0].name});
        const pdfType=e.target.files[0].type
        const pdfSize=e.target.files[0].size
        if (pdfType!=='application/pdf') {
            this.setState({invalidPdf: 'Only PDF allow'})
            this.setState({pdfname: ''})
            this.setState({pdf: ''})
            return false
        } else {
            this.setState({invalidPdf: ''})
        }


        if (pdfSize>1000000) {
            this.setState({invalidPdf: 'Pdf file Max 1MB'})
            this.setState({pdfname: ''})
            this.setState({pdf: ''})
            return false
        } else {
            this.setState({invalidPdf: ''})
        }
    };

    onSubmit=async (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const submitBtn=document.getElementById('submit-btn')
        submitBtn.className='d-none'
        const loadingBtn=document.getElementById('loading-btn')
        loadingBtn.className=loadingBtn.className.replace("d-none", "")
        loadingBtn.className='btn btn-danger';
        try {

            const formData=new FormData();
            formData.append('areaoffice', this.state.areaoffice);
            formData.append('aocode', this.state.aocode);
            formData.append('ao', this.state.ao);
            formData.append('range', this.state.range);
            formData.append('aonumber', this.state.aonumber);
            formData.append('application', this.state.application);
            formData.append('old_pan', this.state.old_pan);
            formData.append('category', this.state.category);
            formData.append('firstname', this.state.firstname);
            formData.append('middlename', this.state.middlename);
            formData.append('lastname', this.state.lastname);
            formData.append('ffirstname', this.state.ffirstname);
            formData.append('fmiddlename', this.state.fmiddlename);
            formData.append('flastname', this.state.flastname);
            formData.append('mfirstname', this.state.mfirstname);
            formData.append('mmiddlename', this.state.mmiddlename);
            formData.append('mlastname', this.state.mlastname);
            formData.append('cardHolder', this.state.cardHolder);
            formData.append('dob', this.state.dob);
            formData.append('contect_number', this.state.contect_number);
            formData.append('email', this.state.email);
            formData.append('proofid', this.state.proofid);
            formData.append('proofadd', this.state.proofadd);
            formData.append('proofdob', this.state.proofdob);
            formData.append('gender', this.state.gender);
            formData.append('adhar_number', this.state.adhar_number);
            formData.append('address_f', this.state.address_f);
            formData.append('address_v', this.state.address_v);
            formData.append('address_p', this.state.address_p);
            formData.append('address_divi', this.state.address_divi);
            formData.append('address_d', this.state.address_d);
            formData.append('state', this.state.state);
            formData.append('applicant', this.state.applicant);
            formData.append('pin_code', this.state.pin_code);
            formData.append('reasonOfReject', this.state.reasonOfReject);
            formData.append('panStatus', this.state.panStatus);
            formData.append('image', this.state.image);
            formData.append('sig', this.state.sig);
            formData.append('pdf', this.state.pdf);

            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (this.state.image==''||this.state.pdf==''||this.state.sig=='') {
                alert('Please select valid Files');
                submitBtn.className=submitBtn.className.replace("d-none", "");
                submitBtn.className='btn btn-danger';
                loadingBtn.className='d-none'
                return false
            }
            if (token) {
                config.headers['x-auth-token']=token;
            }
            const res=await axios.put(`/pan/fileupdate/${this.props.match.params.id}`, formData, config);
            if (res.status==200) {
                submitBtn.className=submitBtn.className.replace("d-none", "");
                submitBtn.className='btn btn-danger';
                loadingBtn.className='d-none'
            }
            this.props.history.push('/mypans')
        } catch (error) {
            console.log(error.response.data.msg);
            if (error.response.data.msg) {
                this.setState({msg: error.response.data.msg});
                document.body.scrollTop=0;
                document.documentElement.scrollTop=0;
                submitBtn.className=submitBtn.className.replace("d-none", "");
                submitBtn.className='btn btn-danger';
                loadingBtn.className='d-none'
            }

        }
    };

    async componentDidMount() {
        try {
            // Get token from localStorage
            const token=localStorage.getItem('token')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token']=token;
            }
            await axios.get(`/pan/${this.props.match.params.id}`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            this.setState({msg: error.response.data})
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/login')
            }
        }
    }
    render() {
        const {msg}=this.state
        return (
            <Fragment>
                <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <div className="container">
                        <div className="card shadow mb-5">
                            <h3 className="card-header bg-dark text-center text-light">Preview</h3>
                            <div className="card-body">
                                {msg.length? msg.map(errMsg => <p key={errMsg.msg} className="alert alert-danger" >{errMsg.msg}</p>):null}
                                <form onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="aocode">Aocode</label>
                                            <input type="text" readOnly name="aocode" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.aocode} placeholder="Enter aocode" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="ao">AO</label>
                                            <input type="text" readOnly name="ao" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.ao} placeholder="Enter AO code" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="range">Range</label>
                                            <input type="text" readOnly name="range" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.range} placeholder="Enter Range code" />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="aonumber">Aonumber</label>
                                            <input type="text" readOnly name="aonumber" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.aonumber} placeholder="Enter Area number code" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="application">Application</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.application} name="application" placeholder="Enter Application Type" />
                                        </div>
                                        {this.state.old_pan? <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="old_pan">Old Pan</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.old_pan} name="old_pan" placeholder="Enter Application Type" />
                                        </div>:null}
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="category">Category</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.category} name="category" placeholder="Enter Category Type of Pan" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="applicant">Applicant</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.applicant} name="applicant" placeholder="Enter Applicant Type of Pan" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="firstname">First Name</label>
                                            <input type="text" name="firstname" value={this.state.firstname} id="firstname" onChange={this.changeHandler} placeholder="Enter User FirstName" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="middlename">Middle Name</label>
                                            <input type="text" name="middlename" value={this.state.middlename} id="middlename" onChange={this.changeHandler} placeholder="Enter MiddleName" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="lastname">Last Name</label>
                                            <input type="text" name="lastname" value={this.state.lastname} id="lastname" onChange={this.changeHandler} placeholder="Enter Last Name" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="ffirstname">Father First Name</label>
                                            <input type="text" name="ffirstname" value={this.state.ffirstname} id="ffirstname" onChange={this.changeHandler} placeholder="Enter Father  FirstName" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="fmiddlename">Father Middle Name</label>
                                            <input type="text" name="fmiddlename" value={this.state.fmiddlename} id="fmiddlename" onChange={this.changeHandler} placeholder="Enter Father MiddleName" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="flastname">Father Last Name</label>
                                            <input type="text" name="flastname" value={this.state.flastname} id="flastname" onChange={this.changeHandler} placeholder="Enter Father Last Name" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="cardHolder">Card Holder Name</label>
                                        <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} name="cardHolder" value={this.state.cardHolder} placeholder="Enter Card Holder Name" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="dob">Date of Birth (DD/MM/YYYY)</label>
                                        <input type="text" value={this.state.dob} className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Date format DD/MM/YYYY" name="dob" />
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="contect_number">Mobile</label>
                                            <input type="text" name="contect_number" value={this.state.contect_number} id="contect_number" onChange={this.changeHandler} placeholder="Enter mobile" className="form-control form-control-sm" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="email">Email</label>
                                            <input type="email" name="email" value={this.state.email} id="email" onChange={this.changeHandler} placeholder="Enter Email" className="form-control form-control-sm" />
                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofid">Proof Of Id</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.proofid} name="proofid" placeholder="Enter Proof Of Id" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofadd">Proof Of Address</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.proofadd} name="proofadd" placeholder="Enter Proof Of Address" />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofdob">Proof Of Date of Birth</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} value={this.state.proofdob} name="proofdob" placeholder="Enter Proof Of Date of Birth" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="gender">Gender</label>
                                        <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} name="gender" value={this.state.gender} placeholder="Enter Gender" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="adhar_number">Adhar Number</label>
                                        <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} name="adhar_number" value={this.state.adhar_number} placeholder="Enter Adhar Number" />
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="address_f">Address of Office</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Address of Office" name="address_f" value={this.state.address_f} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="address_v">Address of Village</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Address of Village" name="address_v" value={this.state.address_v} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="address_p">Address of Post</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Address of Post" name="address_p" value={this.state.address_p} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="address_divi">Address of Division</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Address of Division" name="address_divi" value={this.state.address_divi} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="address_d">Address of Dist.</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Address of Dist." name="address_d" value={this.state.address_d} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="state">State</label>
                                        <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter State" name="state" value={this.state.state} />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="pin_code">Pin Code</label>
                                        <input type="text" className="form-control form-control-sm" onChange={this.changeHandler} placeholder="Enter Pin Code" name="pin_code" value={this.state.pin_code} />
                                    </div>

                                    {this.state.panStatus=='FILE REUPLOAD'||this.state.panStatus=="Please file Upload"? <div className="row">
                                        <div class="pt-3 col-md-4">
                                            <div class="form-file">
                                                <label class="form-file-label" for="customFile">
                                                    Image
                                                </label>
                                                <input type="file" name="image" onChange={this.changePhoto} class="form-control form-control-sm" id="customFile" />
                                                <p className="text text-danger">{this.state.invalidImage}</p>
                                            </div>
                                        </div>
                                        <div class="pt-3 col-md-4">
                                            <div class="form-file">
                                                <label class="form-file-label" for="customFile">
                                                    Signature
                                                </label>
                                                <input type="file" name="sig" onChange={this.changeSig} class="form-control form-control-sm" id="customFile" />
                                                <p className="text text-danger">{this.state.invalidSig}</p>
                                            </div>
                                        </div>
                                        <div class="pt-3 col-md-4">
                                            <div class="form-file">
                                                <label class="form-file-label" for="customFile">
                                                    Pdf Document
                                                </label>
                                                <input type="file" name="pdf" onChange={this.changePdf} class="form-control form-control-sm" id="customFile" />
                                                <p className="text text-danger">{this.state.invalidPdf}</p>
                                            </div>
                                        </div>
                                    </div>:null}
                                    {this.state.panStatus!=='FILE REUPLOAD'&&this.state.panStatus!=="Please file Upload"?
                                        <div className="row">
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <Link className="nav-link" to={`/panfiles/${this.state.image}`} target="true" >
                                                        <img src={`/panfiles/${this.state.image}`} alt="Pan holder image" />
                                                    </Link>
                                                </li>
                                                <li className="nav item">
                                                    <Link to={`/panfiles/${this.state.sig}`} className="nav-link" target="true" >
                                                        <img src={`/panfiles/${this.state.sig}`} alt="Pan holder Signature" />
                                                    </Link>
                                                </li>
                                                <li className="nav item">
                                                    <Link to={`/panfiles/${this.state.pdf}`} className="nav-link" target="true" >
                                                        <embed style={{width: '100%'}} src={`/panfiles/${this.state.pdf}`} type="application/pdf" height="300" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>:null} <br />
                                    {this.state.panStatus=='FILE REUPLOAD'||this.state.panStatus=="Please file Upload"? <div className="singh mt-3">
                                        <button id='submit-btn' className="btn btn-danger">
                                            Submit
                                        </button>
                                        <button id='loading-btn' class="btn d-none btn-danger" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>:null}
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default panById;
