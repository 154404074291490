import React, { Component, useEffect, useState } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader';

const PaymentsByUserid = (props) => {
    const [paymentData, setPaymentData] = useState([]);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        GetMyPans(limit, skip);
    }, [limit, skip]);

    const nextPage = () => {
        setSkip(skip + limit)
        console.log(skip)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const GetMyPans = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            const pans = await axios.post(`/api/paymentsbyUserId?limit=${limit}&skip=${skip}`, { id: props?.location?.state }, config);
            console.log(pans.data)
            setPaymentData(pans.data);
            setLoader(false);
        } catch (error) {
            console.log(error);
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                history.push('/')
            }
        }
    }

    return (
        <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div className="container-fluid">
                <div className="card shadow-lg">
                    <div className="card-header bg-dark">
                        <h3 className="text-center text-light">Payments of { paymentData[0]?.username }</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id='myTable' className="table shadow table-bordered table-hover text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Bank Name</th>
                                        <th scope="col">Refrance Code of transfer</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Status of Payment Request</th>
                                        <th scope="col">Apply Date</th>
                                    </tr>
                                </thead>
                                { paymentData.length ? paymentData.map((pan, index) =>
                                    <tbody key={ index }>
                                        <tr>
                                            <th scope="row">{ index + 1 }</th>
                                            <td>{ pan.bankName }</td>
                                            <td>{ pan.tranferRefCode }</td>
                                            <td>{ pan.totleAmount }</td>
                                            { pan.acceptStatus === 'Accepted' ? <td className="text-center text-light bg-success">{ pan.acceptStatus } </td> : null }
                                            { pan.acceptStatus === 'Rejected' ? <td className="text-center text-light bg-danger">{ pan.acceptStatus },
                                                <span className="text-center"> { pan.reasonOfReject } </span>
                                            </td> : null }
                                            { !pan.acceptStatus ? <td className="text-center text-light bg-secondary">Pending </td> : null }
                                            <td>
                                                <Moment format='lll'>
                                                    { pan.date }
                                                </Moment>
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : null }
                            </table>
                            { loader ? <Loader /> : null }
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a onClick={ previousPage } className="page-link" href="#">Previous</a>
                                </li>
                                <li className="page-item">
                                    <div onClick={ nextPage } className="page-link">Next</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PaymentsByUserid
