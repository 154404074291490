import React, { Component } from 'react'

export class About extends Component
{
    render ()
    {
        return (
            <div>
                <div className="container" >
                    <div class="card mb-3" style={ { marginTop: '70px' } }>
                        <div className="card-header bg-info">
                            <h4 className="text-light text-center card-title">About US</h4>
                        </div>
                        <div class="row g-0">
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">About us</h5>
                                    <p class="card-text">
                                        Welcome to <b>PARAS NET</b> Corporation, your number one source for all digital things. We're dedicated to giving you the very best of online services, with a focus on hassle-free, earning and satisfaction.
                                                <br />
                                        <br />
                                                Our company has come a long way from its beginnings in India. When we first started out, our passion for technology drove them to, do tons of research, so that <b>PARAS NET</b> Corporation can offer you the India's most advanced toothbrush. We now serve customers all over country, and are thrilled that we're able to turn our passion into your own website.
                                                <br /><br />
                                                We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <img src="images/hero_1.jpg" style={ { width: '100%' } } alt="About Us" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About