import React, { Component } from 'react'

export class Services extends Component
{
    render ()
    {
        return (
            <div >
                <div className="container" style={ { marginTop: '70px' } }>
                    <div className="card-header bg-info">
                        <h2 className="text-center text-light">SERVICES</h2>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/aeps.jpg" alt="aeps image" className="card-img-top" />
                                    <h4 className="text-center card-title">
                                        AEPS SERVICE
                                        </h4>
                                    <div className="card-body">
                                        <p>Aadhaar enabled Payment System (AEPS) is a secured payment method provided by the National Payments Corporation of India (NPCI) that allows aadhaar linked bank account holders to avail basic banking services such as balance inquiry & cash withdrawal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/moneyTransfer.jpg" className="card-img-top" alt="Money Transfer" />
                                    <h4 className="text-center card-title">
                                        MONEY TRANSFER
                                        </h4>
                                    <div className="card-body">
                                        <p>Fund transfers will now be made easy with Cash to Account. Carry in cash and walk-in to any Fino Payments Bank branch or select merchant establishments, fill in few details and have the money transferred to your loved ones, anywhere in the country.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/mobileRecharg.jpg" alt="Mobile recharge" className="card-img-top" />
                                    <h4 className="text-center card-title">
                                        MOBILE RECHARGE
                                        </h4>
                                    <div className="card-body">
                                        <p>parasnet.co.in covers major network providers in India, that include Airtel, Aircel, Vodafone, BSNL, Idea, Tata Docomo (GSM), Uninor, etc. Not only that, to facilitate the online recharges, the latest talk time plans and data packs are updated on the our website accordingly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/pancard.jpg" alt="Pan card" className="card-img-top" />
                                    <h4 className="text-center card-title">
                                        PAN CARD
                                        </h4>
                                    <div className="card-body">
                                        <p>A permanent account number is a ten-character alphanumeric identifier, issued in the form of a laminated "PAN card", by the Indian Income Tax Department, to any "person" who applies for it or to whom the department allots the number without an application.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/bbps.jpg" alt="BBPS" className="card-img-top" />
                                    <h4 className="text-center card-title">
                                        BBPS
                                    </h4>
                                    <div className="card-body">
                                        <p>Bharat Bill Payment System is an integrated bill payment system in India offering interoperable and accessible bill payment service to customers through a network of agents of registered member as Agent Institutions, enabling multiple payment modes, and providing instant confirmation of payment.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card mt-3 shadow-sm">
                                    <img src="images/fastage.jpg" alt="Fastage" className="card-img-top" />
                                    <h4 className="text-center card-title">
                                        FASTAG
                                        </h4>
                                    <div className="card-body">
                                        <p>FASTag is an electronic toll collection system in India, operated by the National Highway Authority of India. It employs Radio Frequency Identification technology for making toll payments directly from the prepaid or savings account linked to it or directly toll owner.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Services
