import React, { Component, Fragment } from 'react'
import axios from 'axios';


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            msg: '',
        }

        this.changeHandler = this.changeHandler.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }


    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    onSubmit = async e => {
        e.preventDefault();

        const newUser = {
            email: this.state.email
        }


        try {
            const res = await axios.post('/user/reset-password', newUser, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            this.setState({ msg: res.data.msg })
            if (res.data) {
                this.props.history.push('/reset');
                this.setState({email: ''})
                var newToast=document.getElementById("snackbar");
                newToast.className="show bg-success";
                setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
            }
        } catch (error) {
            if (error.response.data) {
                this.setState({msg: error.response.data.msg})
                var errTost=document.getElementById("snackbar");
                errTost.className="show bg-danger";
                setTimeout(function() {errTost.className=errTost.className.replace("show", "");}, 3000);
            }
        }
    }

    render() {
        // console.log(this.state);
        const {msg}=this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="col-md-4 m-auto" style={{paddingTop: '80px'}}>
                        <div className="card mb-5 shadow">
                            <h3 className="card-header bg-dark text-center text-light">Reset Password</h3>
                            <div className="card-body">
                                {this.state.msg.length? this.state.msg.map(validmsg => (
                                    <div key={validmsg.msg} id='snackbar'>
                                        <strong>{validmsg.msg}</strong>
                                    </div>
                                )):null}
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name="email" value={this.state.email} id="email" onChange={this.changeHandler} placeholder="Enter Email" className="form-control form-control-sm" />
                                    </div>
                                    <button className="btn mt-3 btn-dark">Reset Password</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ResetPassword;
