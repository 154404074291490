import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';

class Register extends Component {

    constructor(props) {
        super(props);
        let token = localStorage.getItem('token')
        let tokens = localStorage.getItem('tokens')
        if (token) {
            this.props.history.push('/profile')
        }
        if (tokens) {
            this.props.history.push('/userAllPan')
        }
        this.state = {
            username: '',
            shopname: '',
            fullname: '',
            email: '',
            password: '',
            mobile: '',
            address: '',
            pannumber: '',
            aadharnumber: '',
            profile: '',
            profilename: 'Choose Profile',
            pancard: '',
            pancardname: 'Choose Pancard',
            aadharcard: '',
            aadharcardname: 'Choose Aadharcard',
            token: null,
            msg: {},
            successMsg: ''
        }

        this.changeHandler = this.changeHandler.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.changeAadhar = this.changeAadhar.bind(this)
        this.changePan = this.changePan.bind(this)
        this.changeProfile = this.changeProfile.bind(this)
    }

    changePan = (e) => {
        this.setState({ pancard: e.target.files[0] });
        this.setState({ pancardname: e.target.files[0].name });
    };
    changeAadhar = (e) => {
        this.setState({ aadharcard: e.target.files[0] });
        this.setState({ aadharcardname: e.target.files[0].name });
    };
    changeProfile = (e) => {
        this.setState({ profile: e.target.files[0] });
        this.setState({ profilename: e.target.files[0].name });
    };


    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    onSubmit = async e => {
        e.preventDefault();
        const newUser = new FormData();
            newUser.append('username', this.state.username);
            newUser.append('fullname', this.state.fullname);
            newUser.append('shopname', this.state.shopname);
            newUser.append('email', this.state.email);
            newUser.append('password', this.state.password);
            newUser.append('mobile', this.state.mobile);
            newUser.append('aadharnumber', this.state.aadharnumber);
            newUser.append('pannumber', this.state.pannumber);
            newUser.append('profile', this.state.profile);
            newUser.append('pancard', this.state.pancard);
            newUser.append('aadharcard', this.state.aadharcard);
            newUser.append('address', this.state.address);


        try {
            const res = await axios.post('/user/create', newUser, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            this.setState({ token: res.data })
            this.setState({ successMsg: res.data.msg })

            if (res.data) {
                this.props.history.push('/Login');
            } else {
                this.props.history.push('/register');
            }
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({ msg: error.response.data.msg })
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            } else {
                this.setState({ msg: error.response.data.errors })
            }
        }
    }


    render() {
        const { msg } = this.state; 
        return (
                    <div className="container">
                 <div className="row" style={{ paddingTop: '50px' }}>
                        <div className="col-md-5 m-auto">
                            <div className="card mt-5 shadow mb-5">
                                <h3 className="card-header bg-dark text-center text-light">Register</h3>
                                <div className="card-body">
                                {msg.length ? msg.map(errMsg =>
                                    <div key={errMsg.msg} className="alert alert-danger" role="alert" aria-live="polite" aria-atomic="true" data-delay="100">
                                        <div role="alert" aria-live="assertive" aria-atomic="true">{errMsg.msg}
                                        </div>
                                    </div>) : null
                                }
                                <form onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="username" className="col-form-label">Username:</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="User name only text" name="username" value={this.state.username} onChange={this.changeHandler} id="username"/>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="fullname" className="col-form-label">Full Name:</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Name" name="fullname" value={this.state.fullname} onChange={this.changeHandler} id="fullname"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="recipient-name" className="col-form-label">Shope name:</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Shope name" name="shopname" value={this.state.shopname} onChange={this.changeHandler} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="email" className="col-form-label">Email:</label>
                                            <input type="email" className="form-control form-control-sm" placeholder="Email id" name="email" value={this.state.email} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="password" className="col-form-label">Password:</label>
                                            <input type="password" className="form-control form-control-sm" placeholder="password" name="password" value={this.state.password} onChange={this.changeHandler} id="password"/>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="mobile" className="col-form-label">Mobile:</label>
                                            <input type="number" className="form-control form-control-sm" placeholder="mobile" name="mobile" value={this.state.mobile} onChange={this.changeHandler} id="mobile"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="pannumber" className="col-form-label">Pan number:</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="pan number" name="pannumber" value={this.state.pannumber} onChange={this.changeHandler} id="pannumber"/>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="aadharnumber" className="col-form-label">Aadhar number:</label>
                                                <input type="number" className="form-control form-control-sm" placeholder="Aadhar number" name="aadharnumber" value={this.state.aadharnumber} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="address" className="col-form-label">Address:</label>
                                            <input type="text" className="form-control form-control-sm" placeholder="Address" name="address" value={this.state.address} onChange={this.changeHandler} />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <div className="form-file">
                                                <input type="file" name="profile" onChange={this.changeProfile} className="form-file-input" id="customFile"/>
                                                    <label className="form-file-label" htmlFor="customFile">
                                                        <span className="form-file-text">{this.state.profilename}</span>
                                                        <span className="form-file-button">Profile image</span>
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <div className="form-file">
                                                <input type="file" name="pancard" onChange={this.changePan} className="form-file-input" id="customFile"/>
                                                    <label className="form-file-label" htmlFor="customFile">
                                                        <span className="form-file-text">{this.state.pancardname}</span>
                                                        <span className="form-file-button">Pan card</span>
                                                    </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <div className="form-file">
                                                <input type="file" name="aadharcard" onChange={this.changeAadhar} className="form-file-input" id="customFile"/>
                                                    <label className="form-file-label" htmlFor="customFile">
                                                        <span className="form-file-text">{this.state.aadharcardname}</span>
                                                        <span className="form-file-button">Aadharcard</span>
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <Link className="nav-link float-right" to="/Login">Already Registered For Login</Link>
                                    <button className="btn btn-dark">Register</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        )
    }
}

export default Register