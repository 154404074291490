import React, { Component } from 'react'
import axios from 'axios'

export class AddBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankname: '',
            ifsccode: '',
            accountnumber: '',
            msg:{}
        }
        this.submitHandle = this.submitHandle.bind(this)
        this.changeHandle = this.changeHandle.bind(this)
    }

    changeHandle = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitHandle = async (e) => {
        e.preventDefault();

        const newAddBank = {
            bankname: this.state.bankname,
            ifsccode: this.state.ifsccode,
            accountnumber: this.state.accountnumber
        }

        try {
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "Application/json"
                }
            };

            if (token) {
                config.headers['x-admin-token'] = token;
            }
            const res = await axios.put(`/api/newbank/add/${this.state._id}`, newAddBank, config);
            if (res.status===200) {
                this.setState({msg: res.data.msg})
                var newToast=document.getElementById("snackbar");
                newToast.className="show bg-success";
                setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
                this.props.history.push('/addbank');
            }
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({ msg: error.response.data.msg })
            } else {
                this.setState({ msg: error.response.data.errors })
            }
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-danger";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);

        }

    }
    
    async componentDidMount() {
        try {

            // Get token from localStorage
            const token = localStorage.getItem('token')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            await axios.get(`/api/new/bank`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            console.log(error.response.status);
            if (error.response.status == 401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/')
            }
        }
    }

    render() {
        const { msg } = this.state;
        return (
            <div>
                <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="card shadow-sm">
                                <div className="card-header bg-dark">
                                    <h3 className="text-light">Add bank</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submitHandle}>
                                        {msg.length ? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>) : null}
                                        <div className="mb-3 col-md-3">
                                            <label className="col-form-label" for="bankname" className="form-label">Bank name</label>
                                            <input type="text" className="form-control form-control-sm" id="bankname" onChange={this.changeHandle} value={this.state.bankname} name="bankname" />
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label for="ifsccode" className="form-label">Bank Ifsc Code</label>
                                            <input type="text" name="ifsccode" value={this.state.ifsccode} onChange={this.changeHandle} className="form-control form-control-sm" id="ifsccode" />
                                        </div>
                                        <div className="mb-3 col-md-3">
                                            <label for="accountnumber" className="form-label">Bank Account number</label>
                                            <input type="text" name="accountnumber" value={this.state.accountnumber} onChange={this.changeHandle} className="form-control form-control-sm" id="accountnumber" />
                                        </div>
                                        <button type="submit" className="btn btn-dark">Add Bank</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </main>
            </div>
        )
    }
}

export default AddBank
