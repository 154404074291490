import React, {Component} from 'react'
import axios from 'axios';
import Moment from 'react-moment';



export class checkUserProfileByAdmin extends Component {
    constructor(props) {
        super(props);
        let loggedIn=false
        this.state={
            totleAmount: '',
            panrate: '',
            userIdStatus: '',
            userData: {},
            msg: {},
            loggedIn
        }
        this.submitHandel=this.submitHandel.bind(this);
        this.changeHandel=this.changeHandel.bind(this)
    }

    changeHandel=(e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    submitHandel=async (e) => {
        e.preventDefault();
        const newUser={
            totleAmount: this.state.totleAmount,
            panrate: this.state.panrate,
            userIdStatus: this.state.userIdStatus
        }
        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            const res=await axios.put(`/user/wallet/${this.props.match.params.id}`, newUser, config);
            this.setState({msg: res.data.msg, userData: res.data})
            var newToast=document.getElementById("snackbar");
            newToast.className="show bg-success";
            setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
            if (res.status==200) {
                this.props.history.push('/allusers')
            }
        } catch (error) {
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('tokens')
                this.props.history.push('/Admin')
            }
        }
    }


    async componentDidMount() {
        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            await axios.get(`/api/userprfileByAdmin/${this.props.match.params.id}`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            this.setState({msg: error.response.data})
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('tokens')
                this.props.history.push('/Admin')
            }
        }
    }

    submit=async (e) => {
        e.preventDefault();
        try {
            const data={
                password: this.state.password
            }
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            await axios.put(`/api/user/password/reset/${this.state._id}`, data, config)
                .then(res => {
                    this.setState({password: '',msg: res.data.msg})
                    var newToast=document.getElementById("snackbar");
                    newToast.className="show bg-success";
                    setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);
                })
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({msg: error.response.data.msg})
            } else {
                this.setState({msg: error.response.data.errors})
            }
            var newToast=document.getElementById("snackbar");
            newToast.className="show bg-danger";
            setTimeout(function() {newToast.className=newToast.className.replace("show", "");}, 3000);

        }
    }

    render() {

        const {userData, msg}=this.state;
        return (
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    {msg.length? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>):null}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card shadow">
                                <div className="card-header">
                                    <h4 className="text text-center text-light">Profile Of {this.state.name}</h4>
                                    <center>
                                        <img style={{width: "300px", borderRadius: "50%", border: '2px solid #fff'}} src='/images/img_avatar.png' alt="" />
                                    </center>
                                </div>
                                <div className="card-body">
                                    <table className="table table-hover">
                                        <tbody>
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>User Name</td>
                                                <td>{this.state.username}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">2</th>
                                                <td>Wallet</td>
                                                {
                                                    this.state.totleAmount? <td>{this.state.totleAmount}</td>:<td>0 Rs.</td>
                                                }
                                            </tr>
                                            <tr>
                                                <th scope="row">3</th>
                                                <td>Name</td>
                                                <td>{this.state.fullname}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">4</th>
                                                <td>Email</td>
                                                <td>{this.state.email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">5</th>
                                                <td>Mobile</td>
                                                <td>{this.state.mobile}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">6</th>
                                                <td>Address</td>
                                                <td>{this.state.address}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">7</th>
                                                <td>Aadhar number</td>
                                                <td>{this.state.aadharnumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">8</th>
                                                <td>Pan Number</td>
                                                <td>{this.state.pannumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">9</th>
                                                <td>Registered Date</td>
                                                <td>
                                                    <Moment format='lll'>
                                                        {this.state.date}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow">
                                <div className="card-header bg-dark">
                                    <h4 className="text-center text-light">User Wallet</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submitHandel}>
                                        <div className="form-group">
                                            <label className="col-form-label" htmlFor="totelAmount">Totel Amount</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandel} name="totleAmount" value={this.state.totleAmount} placeholder="Enter Amount" />
                                        </div>
                                        <input type="submit" className="btn mt-3 btn-dark" />
                                    </form>
                                </div>
                            </div><br />
                            <div className="card shadow">
                                <div className="card-header bg-dark">
                                    <h4 className="text-center text-light">Pan Rate</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submitHandel}>
                                        <div className="form-group">
                                            <label className="col-form-label" htmlFor="panrate">Pan Rate</label>
                                            <input type="text" className="form-control form-control-sm" onChange={this.changeHandel} name="panrate" value={this.state.panrate} placeholder="Enter Amount" />
                                        </div>
                                        <input type="submit" className="btn mt-3 btn-dark" />
                                    </form>
                                </div>
                            </div><br />
                            <div className="card shadow">
                                <div className="card-header bg-dark">
                                    <h4 className="text-center text-light">User id Activate/Deactivate</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submitHandel}>
                                        <div className="form-group">
                                            <label htmlFor="userIdStatus" className="col-form-label">User id status</label>
                                            <select className="form-control form-control-sm" name="userIdStatus" onChange={this.changeHandel} value={this.state.userIdStatus}>
                                                <option className="dropdown-item" value="Activate">Activate</option>
                                                <option className="dropdown-item" value="Deactivate">Deactivate</option>
                                            </select>
                                        </div>
                                        <input type="submit" className="btn mt-3 btn-dark" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card  shadow-sm">
                                <div className="card-header text-light bg-dark">
                                    <h3 className="text-center">Password Reset</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.submit}>
                                        <div className="form-field">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className='form-control form-control-sm' name="password" id="password" onChange={this.changeHandel} value={this.state.password} />
                                        </div>
                                        <button className="btn mt-2 btn-dark">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default checkUserProfileByAdmin
