import React, {useEffect, useState} from 'react'
import {useHistory, Link} from 'react-router-dom';
import axios from 'axios';
import UploadSlip from './UploadSlip';
import Moment from 'react-moment';

const ReuploadData=() => {
    const [pans, setPans]=useState([]);
    const [msg, setMsg]=useState([]);
    const [loggedIn, setLoggedIn]=useState(true);
    const [limit, setLimit]=useState(10);
    const [skip, setSkip]=useState(0);
    const history=useHistory();
    const nextPage=() => {
        setSkip(skip+limit)
    }
    const previousPage=() => {
        setSkip(skip-limit)
    }
    const getData=async (limit, skip) => {
        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            await axios.get(`/pan/verify/pans?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    const reverseData=res.data
                    setPans(reverseData)
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status==401) {
                setLoggedIn(false)
                localStorage.removeItem('token')
                history.push('/Admin')
            }
        }
    }
    useEffect(() => {
        getData(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="card mb-5">
                        <div className="card-header bg-dark">
                            <h4 className="text text-center text-light">Verify or Upload slip Pans</h4>
                        </div>
                        <div className="card-body shadow">
                            <div className="table-responsive">
                                <table id='myTable' className="table table-bordered text-center shadow table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Pan Apply User</th>
                                            <th scope="col">Pan Holder</th>
                                            <th scope="col">Apply Date</th>
                                            <th scope="col">Documents</th>
                                            <th scope="col">Upload Slip</th>
                                            <th scope="col">Pan Status</th>
                                            <th scope="col">Apply Date</th>
                                            <th scope="col">Update Date</th>
                                        </tr>
                                    </thead>
                                    {
                                        pans.length? pans.map((pan, index) => {
                                            return <tbody key={index}>
                                                <tr>
                                                    <th scope="row">{index+1}</th>
                                                    <td>
                                                        <img style={{width: "30px", borderRadius: "50%"}} src={pan.avatar} alt="" />
                                                        &nbsp; {pan.username}
                                                    </td>
                                                    <td>
                                                        <Link className="nav-link" target='_blank' to={`/update/${pan._id}`}>
                                                            {pan.cardHolder}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            {pan.date}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <ul className="nav">
                                                            <li className="nav-item">
                                                                <Link className="nav-link" to={`/panfiles/${pan.image}`} target="true" >Image</Link>
                                                            </li>
                                                            <li className="nav item">
                                                                <Link to={`/panfiles/${pan.sig}`} className="nav-link" target="true" >Sig</Link>
                                                            </li>
                                                            <li className="nav item">
                                                                <Link to={`/panfiles/${pan.pdf}`} className="nav-link" target="true" >Document</Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    {pan.slip&&pan.panStatus=='Data Reuploaded'? <td>{pan.acknowledgement}</td>:<UploadSlip userId={pan._id} />}

                                                    <td className={pan.panStatus=='Data Reuploaded'? 'bg-warning':''}>{pan.panStatus}
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            {pan.createdAt}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            {pan.updatedAt}
                                                        </Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }):null
                                    }
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={previousPage} className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={nextPage} className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ReuploadData
