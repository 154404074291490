import React, {Component} from 'react';
import axios from 'axios';

export class AcceptPayment extends Component {
    constructor(props) {
        super(props);
        this.state={
            acceptStatus: '',
            reasonOfReject: '',
            paymentData: {}
        }
        this.successHandle=this.successHandle.bind(this);
        this.changeHandle=this.changeHandle.bind(this)
    }
    changeHandle=e => {
        this.setState({[e.target.name]: e.target.value})
    }


    successHandle=async e => {
        e.preventDefault();
        const newUser={
            acceptStatus: this.state.acceptStatus,
            reasonOfReject: this.state.reasonOfReject
        }

        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            const res=await axios.put(`/api/payment/${this.props.match.params.id}`, newUser, config);
            this.setState({token: res.data})
            if (res.data.data) {
                this.props.history.push('/payments')
            }
        } catch (error) {
            this.setState({msg: error.response.data})
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('tokens')
                this.props.history.push('/Admin')
            }
        }
    }


    render() {
        return (
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 m-auto">
                            <div className="card" >
                                <div className="card-header bg-dark">
                                    <h3 className="text text-light taxt-center">
                                        Accept Payment
                               </h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.successHandle}>
                                        <div className="custom-control custom-radio" onChange={this.changeHandle}>
                                            <input type="radio" value="Accepted" id="customRadio1" name="acceptStatus" className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="customRadio1">Accepted</label>
                                        </div>
                                        <div className="custom-control custom-radio" onChange={this.changeHandle}>
                                            <input type="radio" value="Rejected" id="customRadio2" name="acceptStatus" className="custom-control-input" />
                                            <label className="custom-control-label" htmlFor="customRadio2">Rejected</label>
                                        </div>
                                        {this.state.acceptStatus==='Rejected'? <div className="form-group mt-2">
                                            <label htmlFor="reasonOfReject">Reason Of Reject</label>
                                            <input type="text" name="reasonOfReject" onChange={this.changeHandle} value={this.state.reasonOfReject} className="form-control" placeholder="Reason of reject" />
                                        </div>:null}
                                        <input type="submit" className="btn btn-danger my-3" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default AcceptPayment
