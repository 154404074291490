import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';
import Moment from 'react-moment';


const AllUsers = () => {
    const [codes, setCodes] = useState([]);
    const [msg, setMsg] = useState([]);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [aodata, setAodata] = useState({
        areacode: '',
        aotype: '',
        aonumber: '',
        discription: '',
        discription1: '',
        rangecode: '',
    })
    const [loader, setLoader] = useState(false);

    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const history = useHistory();
    const nextPage = () => {
        setSkip(skip + limit)
        console.log(skip)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const changeHandle = (e) => {
        setAodata({ ...aodata, [e.target.name]: e.target.value })
    }

    const codeSubmit = async (e) => {
        e.preventDefault();

        const aoCodeData = {
            areacode: aodata.areacode,
            aotype: aodata.aotype,
            aonumber: aodata.aonumber,
            discription: aodata.discription,
            discription1: aodata.discription1,
            rangecode: aodata.rangecode
        }
        try {
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "Application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            if (aodata._id) {
                const res = await axios.put(`/codes/api/update/${aodata._id}`, aoCodeData, config);
                if (res.status === 200) {
                    setAodata({ areacode: '', aotype: '', aonumber: '', rangecode: '', discription: '', discription1: '' })
                }
            } else {
                const res = await axios.post(`/codes/api/codes/add`, aoCodeData, config);
                if (res.status === 200) {
                    setAodata({ areacode: '', aotype: '', aonumber: '', rangecode: '', discription: '', discription1: '' })
                    setMsg(res.data.msg)
                    var newToast = document.getElementById("snackbar");
                    newToast.className = "show bg-success";
                    setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
                }
            }
        } catch (error) {
            if (error.response.data.msg) {
                setMsg(error.response.data.msg)
            } else {
                setMsg(error.response.data.errors)
            }
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-danger";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
        }
    }
    const GetData = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/codes/api/get/codes?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    const reverseData = res.data.reverse()
                    setCodes(reverseData)
                    setLoader(false)
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                history.push('/Admin')
            }
        }
    }
    useEffect(() => {
        GetData(limit, skip);
    }, [limit, skip])


    /**@thisIsForEDitPanCodes */
    const ForEdit = async (code) => {
        setAodata(code);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    /**@thisIsForDeleteCode */
    const ForDelete = async (codeid) => {
        const res = window.confirm('Are you sure Delete this Code')
        if (res == true) {
            try {
                // Get token from localStorage
                const token = localStorage.getItem('tokens')
                // Headers
                const config = {
                    headers: {
                        "Content-type": "application/json"
                    }
                };
                if (token) {
                    config.headers['x-admin-token'] = token;
                }
                await axios.delete(`/codes/api/delete/${codeid}`, config)
                    .then(res => {
                        setMsg(res.data.msg)
                    })
            } catch (error) {
                console.log(error.response.status);
                if (error.response.status == 401) {
                    localStorage.removeItem('tokens')
                    history.push('/')
                }
            }
        }
    }

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="card mb-5">
                        <div className="card-header bg-dark">
                            <h4 className="text-center text-light">Area codes Add</h4>
                        </div>
                        <div className="card-body">
                            <form onSubmit={e => codeSubmit(e)}>
                                {msg.length ? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>) : null}
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="areacode">Area code</label>
                                            <input type="text" onChange={e => changeHandle(e)} value={aodata.areacode} name="areacode" id="areacode" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="aotype">Ao type</label>
                                            <input type="text" onChange={e => changeHandle(e)} name="aotype" value={aodata.aotype} id="aotype" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="rangecode">Range code</label>
                                            <input type="text" onChange={e => changeHandle(e)} value={aodata.rangecode} name="rangecode" id="rangecode" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="aonumber">Ao Number</label>
                                            <input type="text" onChange={e => changeHandle(e)} name="aonumber" value={aodata.aonumber} id="aonumber" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="discription1">Ward/Circle/Range/ Commissioner</label>
                                            <input type="text" onChange={e => changeHandle(e)} value={aodata.discription} name="discription" id="discription" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-field">
                                            <label className='form-label' htmlFor="discription">Discription</label>
                                            <input type="text" onChange={e => changeHandle(e)} value={aodata.discription1} name="discription1" id="discription1" className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-dark mt-2 float-right">Code Add</button>
                            </form>
                        </div>
                        <div className="mt-5">
                            <div className="card-header bg-dark">
                                <h4 className="text text-center text-light">Codes</h4>
                            </div>
                            <div className="card-body shadow">
                                <div className="card-body">
                                    <table id="myTable" class="table table-hover text-center table-responsive table-bordered ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Sr. N.</th>
                                                <th scope="col">Area Code</th>
                                                <th scope="col">Ao Type</th>
                                                <th scope="col">Range code</th>
                                                <th scope="col">Ao number</th>
                                                <th scope="col">Ward/Circle/Range/ Commissioner</th>
                                                <th scope="col">Discription</th>
                                                <th scope="col">Action</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        {codes.length ? codes.map((code, index) => {
                                            return (
                                                <tbody key={index}>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{code.areacode}</td>
                                                        <td>{code.aotype}</td>
                                                        <td>{code.rangecode}</td>
                                                        <td>{code.aonumber}</td>
                                                        <td>{code.discription}</td>
                                                        <td>{code.discription1}</td>
                                                        <td>
                                                            <Link>
                                                                <i onClick={() => ForDelete(code._id)} class="material-icons align-bottom nav-link">delete</i></Link>
                                                            <Link>
                                                                <i onClick={() => ForEdit(code)} class="material-icons nav-link align-bottom">edit &nbsp;</i>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <Moment format='lll'>
                                                                {code.date}
                                                            </Moment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        }) : <Loader />
                                        }
                                    </table>
                                    {loader ? <Loader /> : null}
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a onClick={previousPage} className="page-link" href="#">Previous</a>
                                        </li>
                                        <li className="page-item">
                                            <div onClick={nextPage} className="page-link">Next</div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AllUsers
