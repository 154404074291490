import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from '../Components/Home'
import PageNotFind from '../Components/PageNotFind'
import NewPassword from '../Components/NewPassword'
import ResetPassword from '../Components/ResetPassword'
import NewPan from '../Components/user/Newpan'
import panById from '../Components/user/PanById'
import AddPayment from '../Components/user/AddPayment'
import userDashboard from '../Components/user/Userdashboard'
import myPayments from '../Components/user/MyPayments'
import Profile from '../Components/user/Profile'
import MyPans from '../Components/user/MyPans'
import AcceptPayment from '../Components/admin/AcceptPayment'
import checkUserProfileByAdmin from '../Components/admin/CheckUserProfileByAdmin'
import allpayments from '../Components/admin/Allpayments'
import userAllPan from '../Components/admin/UserAllPan'
import adminDashboard from '../Components/admin/AdminDashboard'
import adminLogin from '../Components/admin/AdminLogin'
import UpdatePanByAdmin from '../Components/admin/UpdatePanByAdmin'
import VerifyPanByAdmin from '../Components/admin/VerifyPanByAdmin'
import AllUsers from '../Components/admin/AllUsers'
import CompletePan from '../Components/admin/CompletePan'
import MyPaymentHistory from '../Components/user/MyPaymentHistory'
import Login from '../Components/Login'
import Register from '../Components/Register'
import AddBank from '../Components/admin/AddBank'
import Contact from '../Components/Contact'
import About from '../Components/About'
import Services from '../Components/Services'
import AoCodes from '../Components/admin/AoCodes'
import AdminProfile from '../Components/admin/Profile';
import Setting from '../Components/admin/Setting'
import ReuploadData from '../Components/admin/ReuploadData'
import PanByUser from '../Components/admin/PanByUser'
import PaymentHistoryOfuser from '../Components/admin/PaymentHisotryOfUser'
import PaymentsByUserid from '../Components/admin/PaymentsByUserid'

const ProtectRoute = ({ component: Component, ...rest }) => {
    return <Route { ...rest } render={ (props) => {
        return localStorage.getItem('token') ? <Component { ...props } /> : <Redirect to="/" />
    } } />
}

const AdminRoute = ({ component: Component, ...rest }) => {
    return <Route { ...rest } render={ (props) => {
        return localStorage.getItem('tokens') ? <Component { ...props } /> : <Redirect to="/Admin" />

    } } />
}


const Routers = () => {
    return (
        <section>
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route exact path="/reset" component={ ResetPassword } />
                <Route exact path="/Admin" component={ adminLogin } />
                <Route exact path="/reset/:token" component={ NewPassword } />
                <Route exact path="/login" component={ Login } />
                <Route exact path='/contact' component={ Contact } />
                <Route exact path="/about" component={ About } />
                <Route exact path="/service" component={ Services } />
                <Route exact path="/register" component={ Register } />
                <ProtectRoute exact path="/profile" component={ Profile } />
                <ProtectRoute exact path="/payment" component={ AddPayment } />
                <ProtectRoute exact path="/dashboard" component={ userDashboard } />
                <ProtectRoute exact path="/NewPan" component={ NewPan } />
                <ProtectRoute exact path="/pancard/:id" component={ panById } />
                <ProtectRoute exact path="/mypayments" component={ myPayments } />
                <ProtectRoute exact path="/mypans" component={ MyPans } />
                <ProtectRoute exact path="/paymenthistory" component={ MyPaymentHistory } />
                <AdminRoute exact path="/userAllPan" component={ userAllPan } />
                <AdminRoute exact path="/payments" component={ allpayments } />
                <AdminRoute exact path="/acceptPayment/:id" component={ AcceptPayment } />
                <AdminRoute exact path="/admindashboard" component={ adminDashboard } />
                <AdminRoute exact path="/user/:id" component={ checkUserProfileByAdmin } />
                <AdminRoute exact path="/update/:id" component={ UpdatePanByAdmin } />
                <AdminRoute exact path="/verifypan" component={ VerifyPanByAdmin } />
                <AdminRoute exact path="/allusers" component={ AllUsers } />
                <AdminRoute exact path="/completepan" component={ CompletePan } />
                <AdminRoute exect path="/addbank" component={ AddBank } />
                <AdminRoute exect path="/codes" component={ AoCodes } />
                <AdminRoute exect path="/admin/profile" component={ AdminProfile } />
                <AdminRoute exect path="/admin/setting" component={ Setting } />
                <AdminRoute exect path="/admin/reupload" component={ ReuploadData } />
                <AdminRoute exect path="/admin/allpanofuser/:id" component={ PanByUser } />
                <AdminRoute exect path="/admin/paymenthistory" component={ PaymentHistoryOfuser } />
                <AdminRoute exect path="/admin/paymentbyuserid" component={ PaymentsByUserid } />
                <Route exact component={ PageNotFind } />
            </Switch>
        </section>
    )
}

export default Routers
