import React, { Fragment } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import Header from './Components/Header';
import Routing from './Routing/Routers'

function App() {
  return (
    <div>
      <Router>
        <Fragment>
          <Header />
          <Route component={ Routing } />
        </Fragment>
      </Router>
    </div>
  );
}

export default App;
