import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';

const PaymentHistoryOfuser = (props) => {
    const history = useHistory();
    const [allPayments, setAllpayments] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [loader, setLoader] = useState(false);
    // console.log('props', props.location.state)
    const nextPage = () => {
        setSkip(skip + limit)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }

    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const getAllpayments = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.post(`/api/paymentHistoryOfUser?limit=${limit}&skip=${skip}`, { id: props?.location?.state }, config)
                .then(res => {
                    setAllpayments(res.data)
                    setLoader(false)
                    // console.log(res.data);
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                setLoggedIn(false)
                localStorage.removeItem('tokens')
                history.push('/Admin')
            }
        }
    }

    useEffect(() => {
        getAllpayments(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    <div className="card shadow">
                        <div className="card-header bg-dark">
                            <h5 className="text text-center text-light">All Payments history of { allPayments[0]?.username }</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id='myTable' className="table table-bordered shadow table-hover text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Totle Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Apply Date</th>
                                        </tr>
                                    </thead>
                                    { allPayments.length ? allPayments.map((pan, index) =>
                                        <tbody key={ index }>
                                            <tr>
                                                <th scope="row">{ index + 1 }</th>
                                                <td>{ pan.amount }</td>
                                                <td>{ pan.totleBalance }</td>
                                                <td>{ pan.status }</td>
                                                <td>
                                                    <Moment format='lll'>
                                                        { pan.date }
                                                    </Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : null }
                                </table>
                                { loader ? <center>
                                    <Loader />
                                </center> : null }
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={ previousPage } className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={ nextPage } className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PaymentHistoryOfuser
