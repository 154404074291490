import React, {Component} from 'react';
import axios from 'axios';

export class NewPassword extends Component {
    constructor(props) {
        super(props)
        this.state={
            password: '',
            msg: {}
        }
    }
    changeHandle=(e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    SubmitHandle=async (e) => {
        e.preventDefault();
        try {
            const user={
                password: this.state.password,
                token: this.props.match.params.token
            }
            const res=await axios.post('/user/new-password', user, {
                headers: {
                    'Content-type': "Application/json"
                }
            });
            if (res.status===200) {
                this.setState({msg: res.data.msg});
                var x=document.getElementById("snackbar");
                x.className="show bg-success";
                setTimeout(function() {x.className=x.className.replace("show", "");}, 3000);
            }
        } catch (error) {
            if (error) {
                this.setState({msg: error.response.data.msg});
                var forErrMsg=document.getElementById("snackbar");
                forErrMsg.className="show bg-danger";
                setTimeout(function() {forErrMsg.className=forErrMsg.className.replace("show", "");}, 3000);
            }
        }
    }
    render() {
        const {msg}=this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 m-auto">
                        <div className="card shadow-sm my-5">
                            <div className="card-header bg-dark text-center text-light">
                                <h3 className="text-center text-light">
                                    Create New Password
                                </h3>
                            </div>
                            <div className="card-body">
                                {msg.length? msg.map(errMsg => (
                                    <div key={errMsg.msg} id="snackbar">
                                        <strong>{errMsg.msg}</strong>
                                    </div>
                                )):null}
                                <form onSubmit={this.SubmitHandle}>
                                    <div className="row">
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <input type="password" className="form-control form-control-sm" id="password" value={this.state.password} onChange={this.changeHandle} name='password' aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewPassword
