import React, { Component, useEffect, useState } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader';

const MyPans = () => {
    const [myPanData, setMyPanData] = useState([]);
    const [msg, setMsg] = useState([]);
    const [slip, setSlip] = useState([]);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState([]);
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        GetMyPans(limit, skip);
    }, [limit, skip]);

    const nextPage = () => {
        setSkip(skip + limit)
        console.log(skip)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const GetMyPans = async (limit, skip) => {
        try {
            setLoader(true)
            // Get token from localStorage
            const token = localStorage.getItem('token')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const pans = await axios.get(`/pan/myPans/user?limit=${limit}&skip=${skip}`, config);
            setMyPanData(pans.data);
            setLoader(false)
        } catch (error) {
            console.log(error);
            if (error.response.status == 401) {
                localStorage.removeItem('token')
                history.push('/')
            }
        }
    }
    const changeHandle = async (e) => {
        setSearch(e.target.value.toUpperCase())
        if (e.target.name === 'search') {
            try {
                // Get token from localStorage
                const token = localStorage.getItem('token')
                // Headers
                const config = {
                    headers: {
                        "Content-type": "application/json"
                    }
                };
                if (token) {
                    config.headers['x-auth-token'] = token;
                }
                const res = await axios.post(`/pan/search/foruser`, { text: search }, config)
                const reverseData = res.data.reverse()
                setMyPanData(reverseData)
            } catch (error) {
                setMsg(error.response.data)
                if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    history.push('/login')
                }
            }
        }
    }
    return (
        <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
            <div className="container-fluid">
                <div className="input-group input-group-sm mb-3 col-md-2">
                    <span className="input-group-text col-md-2" id="inputGroup-sizing-sm">Search</span>
                    <input type="text" className="form-control" value={search}
                        onChange={changeHandle} aria-label="Sizing example input" name='search' aria-describedby="inputGroup-sizing-sm" />
                </div>
                <div className="card shadow-lg">
                    <div className="card-header bg-dark">
                        <h3 className="text-center text-light">My Pan Card</h3>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table id='myTable' className="table shadow table-bordered table-hover text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Pan Holder</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Status of Pan</th>
                                        <th scope="col">DownLoad Slip</th>
                                        <th scope="col">Apply Date</th>
                                    </tr>
                                </thead>
                                {myPanData.length ? myPanData.map((pan, index) =>
                                    <tbody key={index}>
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>
                                                <Link className="nav-link" to={`/pancard/${pan._id}`}>
                                                    {pan.cardHolder}
                                                </Link>
                                            </td>
                                            <td>{pan.email}</td>
                                            {pan.panStatus === 'VERIFY' ? <td className="bg-success"> {pan.panStatus}</td> : pan.panStatus ? <td className="bg-danger">{pan.panStatus} <small>{pan.reasonOfReject}</small> </td> : <td className="bg-secondary">Panding</td>}


                                            {pan.slip ? <td>
                                                <Link className="nav-link" to={`/panfiles/${pan.slip}`} target='_blank' download>{
                                                    pan.acknowledgement ? pan.acknowledgement : 'Slip'
                                                }</Link>
                                            </td> : <td>Panding...</td>}

                                            <td>
                                                <Moment format='lll'>
                                                    {pan.date}
                                                </Moment>
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : null}
                            </table>
                            {loader ? <Loader /> : null}
                        </div>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a onClick={previousPage} className="page-link" href="#">Previous</a>
                                </li>
                                <li className="page-item">
                                    <div onClick={nextPage} className="page-link">Next</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default MyPans
