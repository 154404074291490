import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

// const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
// const adharRegex = RegExp(/\d{12}$/);
// const mobileRegex = RegExp(/\d{10}$/);


class NewPan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areaoffice: '',
            aocode: '',
            ao: '',
            range: '',
            aonumber: '',
            application: '',
            old_pan: '',
            category: '',
            applicant: '',
            firstname: '',
            middlename: '',
            lastname: '',
            ffirstname: '',
            fmiddlename: '',
            flastname: '',
            mfirstname: '',
            mmiddlename: '',
            mlastname: '',
            cardHolder: '',
            dob: '',
            contect_number: '',
            email: '',
            proofid: '',
            proofadd: '',
            proofdob: '',
            gender: '',
            adhar_number: '',
            address_f: '',
            address_v: '',
            address_p: '',
            address_divi: '',
            address_d: '',
            state: '',
            pin_code: '',
            parents: '',
            reasonOfReject: '',
            panStatus: '',
            imagename: 'Choose Image file',
            image: '',
            sig: '',
            signame: 'Choose Signature file',
            pdf: '',
            pdfname: 'Choose Document File',
            token: null,
            invalidImage: '',
            invalidSig: '',
            invalidPdf: '',
            msg: {}
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changePhoto = this.changePhoto.bind(this);
        this.changeSig = this.changeSig.bind(this);
        this.changePdf = this.changePdf.bind(this);
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value.toUpperCase() });
        e.preventDefault();
    };

    changePhoto = (e) => {
        this.setState({ image: e.target.files[0] });
        this.setState({ imagename: e.target.files[0].name });
    };
    changeSig = (e) => {
        this.setState({ sig: e.target.files[0] });
        this.setState({ signame: e.target.files[0].name });
    };
    changePdf = (e) => {
        this.setState({ pdf: e.target.files[0] });
        this.setState({ pdfname: e.target.files[0].name });
    };

    onSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        try {

            const formData = new FormData();
            // formData.append('areaoffice', this.state.areaoffice);
            formData.append('aocode', this.state.aocode);
            formData.append('ao', this.state.ao);
            formData.append('range', this.state.range);
            formData.append('aonumber', this.state.aonumber);
            formData.append('application', this.state.application);
            formData.append('old_pan', this.state.old_pan);
            formData.append('category', this.state.category);
            formData.append('firstname', this.state.firstname);
            formData.append('middlename', this.state.middlename);
            formData.append('lastname', this.state.lastname);
            formData.append('ffirstname', this.state.ffirstname);
            formData.append('fmiddlename', this.state.fmiddlename);
            formData.append('flastname', this.state.flastname);
            formData.append('cardHolder', this.state.cardHolder);
            formData.append('dob', this.state.dob);
            formData.append('contect_number', this.state.contect_number);
            formData.append('email', this.state.email);
            formData.append('proofid', this.state.proofid);
            formData.append('proofadd', this.state.proofadd);
            formData.append('proofdob', this.state.proofdob);
            formData.append('gender', this.state.gender);
            formData.append('adhar_number', this.state.adhar_number);
            formData.append('address_f', this.state.address_f);
            formData.append('address_v', this.state.address_v);
            formData.append('address_p', this.state.address_p);
            formData.append('address_divi', this.state.address_divi);
            formData.append('address_d', this.state.address_d);
            formData.append('state', this.state.state);
            formData.append('applicant', this.state.applicant);
            formData.append('pin_code', this.state.pin_code);
            formData.append('reasonOfReject', this.state.reasonOfReject);
            formData.append('panStatus', this.state.panStatus);
            formData.append('image', this.state.image);
            formData.append('sig', this.state.sig);
            formData.append('pdf', this.state.pdf);

            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            const res = await axios.put(`/pan/update/${this.props.match.params.id}`, formData, config);
            this.setState({ token: res.data.user });
            this.props.history.push('/userAllPan')
        } catch (error) {
            console.log(error.response.data.msg);
            if (error.response.data.msg) {
                this.setState({ msg: error.response.data.msg });
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }

        }
    };

    DeleteHandler = async (e) => {
        // Get token from localStorage
        const token = localStorage.getItem('tokens')
        // Headers
        const config = {
            headers: {
                "Content-type": "application/json"
            }
        };
        if (token) {
            config.headers['x-admin-token'] = token;
        }
        e.preventDefault();
        const res = await axios.delete(`/pan/${this.props.match.params.id}`, config)
        alert(res.data.msg[0].msg)
        this.props.history.push('/userAllPan')
    }

    async componentDidMount() {
        try {
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/pan/pans/${this.props.match.params.id}`, config)
                .then(res => {
                    this.setState(res.data)
                })
        } catch (error) {
            this.setState({ msg: error.response.data })
            if (error.response.status == 401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('token')
                this.props.history.push('/Admin')
            }
        }
    }

    render() {


        return (
            <Fragment>
                <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <div className="container">
                        <div className="card shadow-lg mb-5">
                            <h3 className="card-header bg-dark text-center text-light">Check New Pan for updates</h3>
                            <div className="card-body">
                                <form onSubmit={this.onSubmit} noValidate>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="validationCustom01">Areaoffice</label>
                                            <input
                                                type="text"
                                                name="areaoffice"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                id="validationCustom01"
                                                noValidate
                                                value={this.state.areaoffice}
                                                placeholder="Area Office code"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="aocode">Aocode</label>
                                            <input
                                                type="text"
                                                name="aocode"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                value={this.state.aocode}
                                                placeholder=" aocode"
                                            />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="ao">AO</label>
                                            <input
                                                type="text"
                                                name="ao"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                value={this.state.ao}
                                                placeholder=" AO code"
                                            />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="range">Range</label>
                                            <input
                                                type="text"
                                                name="range"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                value={this.state.range}
                                                placeholder=" Range code"
                                            />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label className="col-form-label" htmlFor="aonumber">Aonumber</label>
                                            <input
                                                type="text"
                                                name="aonumber"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                value={this.state.aonumber}
                                                placeholder=" Area number code"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="application">Type of Application</label>
                                            <input type="text"
                                                name="application"
                                                id="application"
                                                onChange={this.changeHandler}
                                                value={this.state.application}
                                                className="form-control form-control-sm"
                                                placeholder="Application"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="old_pan">Old Pan</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                value={this.state.old_pan}
                                                noValidate
                                                name="old_pan"
                                                placeholder=" Application Type"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="category">Category</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                value={this.state.category}
                                                noValidate
                                                name="category"
                                                placeholder="category"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="applicant">Applicant</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                value={this.state.applicant}
                                                noValidate
                                                name="applicant"
                                                placeholder="applicant"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="firstname">First Name</label>
                                            <input
                                                type="text"
                                                name="firstname"
                                                value={this.state.firstname}
                                                id="firstname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="FirstName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="middlename">Middle Name</label>
                                            <input
                                                type="text"
                                                name="middlename"
                                                value={this.state.middlename}
                                                id="middlename"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" MiddleName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="lastname">Last Name</label>
                                            <input
                                                type="text"
                                                name="lastname"
                                                value={this.state.lastname}
                                                id="lastname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Last Name"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="ffirstname">Father First Name</label>
                                            <input
                                                type="text"
                                                name="ffirstname"
                                                value={this.state.ffirstname}
                                                id="ffirstname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Father  FirstName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="fmiddlename">Father Middle Name</label>
                                            <input
                                                type="text"
                                                name="fmiddlename"
                                                value={this.state.fmiddlename}
                                                id="fmiddlename"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Father MiddleName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="flastname">Father Last Name</label>
                                            <input
                                                type="text"
                                                name="flastname"
                                                value={this.state.flastname}
                                                id="flastname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Father Last Name"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="mfirstname">Mother First Name</label>
                                            <input
                                                type="text"
                                                name="mfirstname"
                                                value={this.state.mfirstname}
                                                id="mfirstname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Mother  FirstName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="mmiddlename">Mother Middle Name</label>
                                            <input
                                                type="text"
                                                name="mmiddlename"
                                                value={this.state.mmiddlename}
                                                id="mmiddlename"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Mother MiddleName"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="mlastname">Mother Last Name</label>
                                            <input
                                                type="text"
                                                name="mlastname"
                                                value={this.state.mlastname}
                                                id="mlastname"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Mother Last Name"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="cardHolder">Card Holder Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                name="cardHolder"
                                                value={this.state.cardHolder}
                                                placeholder=" Card Holder Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="col-form-label" htmlFor="dob">Date of Birth</label>
                                            <input
                                                type="text"
                                                value={this.state.dob}
                                                noValidate
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                name="dob"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="contect_number">Mobile</label>
                                            <input
                                                type="text"
                                                name="contect_number"
                                                value={this.state.contect_number}
                                                id="contect_number"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" mobile"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={this.state.email}
                                                id="email"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Email"
                                                className="form-control form-control-sm"
                                            />
                                            <small id="emailHelp" className="form-text text-muted">
                                                We'll never share your email with anyone else.
                                            </small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofid">Proof Of Id</label>
                                            <input
                                                type="text"
                                                name="proofid"
                                                value={this.state.proofid}
                                                id="proofid"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="proofid"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofadd">Proof Of Address</label>
                                            <input
                                                type="text"
                                                name="proofadd"
                                                value={this.state.proofadd}
                                                id="proofadd"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="proofadd"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="proofdob">Proof Of Date of Birth</label>
                                            <input
                                                type="text"
                                                name="proofdob"
                                                value={this.state.proofdob}
                                                id="proofdob"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="proofdob"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="state">Gender</label>
                                            <input
                                                type="text"
                                                name="gender"
                                                value={this.state.gender}
                                                id="gender"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="gender"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="adhar_number">Adhar Number</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                name="adhar_number"
                                                value={this.state.adhar_number}
                                                placeholder=" Adhar Number"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="address_f">Address of Office</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                placeholder=" Address of Office"
                                                name="address_f"
                                                value={this.state.address_f}
                                                noValidate
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="address_v">Address of Village</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                placeholder=" Address of Village"
                                                name="address_v"
                                                noValidate
                                                value={this.state.address_v}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="address_p">Address of Post</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                placeholder=" Address of Post"
                                                noValidate
                                                name="address_p"
                                                value={this.state.address_p}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="address_divi">Address of Division</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                placeholder=" Address of Division"
                                                noValidate
                                                name="address_divi"
                                                value={this.state.address_divi}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="address_d">Address of Dist.</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Address of Dist."
                                                name="address_d"
                                                value={this.state.address_d}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="state">State</label>
                                            <input
                                                type="text"
                                                name="state"
                                                value={this.state.state}
                                                id="state"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder="state"
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="col-form-label" htmlFor="pin_code">Pin Code</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={this.changeHandler}
                                                noValidate
                                                placeholder=" Pin Code"
                                                name="pin_code"
                                                value={this.state.pin_code}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Link className="nav-link" to={`/panfiles/${this.state.image}`} target="true" >
                                                <img src={`/panfiles/${this.state.image}`} alt="Pan holder image" />
                                            </Link>
                                            <Link to={`/panfiles/${this.state.sig}`} className="nav-link" target="true" >
                                                <img src={`/panfiles/${this.state.sig}`} alt="Pan holder Signature" />
                                            </Link>
                                        </div>
                                        <div className="col-md-9">
                                            <Link to={`/panfiles/${this.state.pdf}`} className="nav-link" target="true" >
                                                <embed style={{ width: '100%' }} src={`/panfiles/${this.state.pdf}`} type="application/pdf" height="300" />
                                            </Link>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="col-form-label" htmlFor="panstatus">Pan Status</label>
                                            <select
                                                className="form-control form-control-sm"
                                                value={this.state.panStatus}
                                                onChange={this.changeHandler}
                                                noValidate
                                                name="panStatus"
                                            >
                                                <option className="dropdown-item">--Select Pan status--</option>
                                                <option className="dropdown-item" value="VERIFY">
                                                    VERIFY
                                                </option>
                                                <option className="dropdown-item" value="REJECTED">
                                                    REJECTED
                                                </option>
                                                <option className="dropdown-item" value="FILE REUPLOAD">
                                                    FILE REUPLOAD
                                                </option>
                                                <option className="dropdown-item" value="MAKENWEPAN">
                                                    MAKE NEW PAN
                                                </option>
                                            </select>
                                        </div>
                                        {
                                            this.state.panStatus === 'REJECT' ?
                                                <div className="form-group col-md-6">
                                                    <label className="col-form-label" htmlFor="pin_code">Reason of Reject</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        onChange={this.changeHandler}
                                                        noValidate
                                                        placeholder="reasonOfReject"
                                                        name="reasonOfReject"
                                                        value={this.state.reasonOfReject}
                                                    />
                                                </div> : null
                                        }
                                        {this.state.panStatus === 'REJECTED' || this.state.panStatus == 'FILE REUPLOAD' ?
                                            <div className="form-group col-md-6">
                                                <label className="col-form-label" htmlFor="reasonOfReject">Reason of Reject</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    name="reasonOfReject"
                                                    onChange={this.changeHandler}
                                                    value={this.state.reasonOfReject}
                                                >
                                                    <option className="dropdown-item">--Select--</option>
                                                    <option className="dropdown-item" value="PDF IS NOT VALID">
                                                        PDF IS NOT VALID
                                                    </option>
                                                    <option className="dropdown-item" value="PHOTO IS NOT VALID">
                                                        PHOTO IS NOT VALID
                                                    </option>
                                                    <option className="dropdown-item" value="SIGNATURE IS NOT VALID">
                                                        SIGNATURE IS NOT VALID
                                                    </option>
                                                    <option className="dropdown-item" value="ADDRESS IS NOT VALID">
                                                        ADDRESS IS NOT VALID
                                                    </option>
                                                    <option className="dropdown-item" value="WRONG FATHER NAME">
                                                        WRONG FATHER NAME
                                                    </option>
                                                    <option className="dropdown-item" value="WRONG MOTHER NAME">
                                                        WRONG MOTHER NAME
                                                    </option>
                                                    <option className="dropdown-item" value="WRONG CARD HOLDER NAME">
                                                        WRONG CARD HOLDER NAME
                                                    </option>
                                                    <option className="dropdown-item" value="DOB IS WRONG">
                                                        DOB IS WRONG
                                                    </option>
                                                    <option className="dropdown-item" value="AREA CODE IS WRONG">
                                                        AREA CODE IS WROKN
                                                    </option>
                                                </select>
                                            </div> : null
                                        }
                                    </div>
                                    <button className="btn btn-danger">Submit</button>
                                    <button className="btn btn-danger float-right" onClick={this.DeleteHandler}>Delete</button>
                                    <small className="float-right">It is to be used as long as the web site remains in testing mode</small>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }
}

export default NewPan;
