import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import axios from 'axios';


class Login extends Component {

    constructor(props) {
        super(props);
        let loggedIn = false
        const token = localStorage.getItem('token')
        if (token) {
            loggedIn = true
        }
        this.state = {
            mobile: '',
            password: '',
            msg: {},
            loggedIn
        }

        this.changeHandler = this.changeHandler.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }


    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    onSubmit = async e => {
        e.preventDefault();

        const user = {
            mobile: this.state.mobile,
            password: this.state.password
        }

        const token = () => {
            localStorage.setItem('token', token)
        }
        try {
            const res = await axios.post('/api/login', user, {
                headers: {
                    'Content-type': 'Application/json'
                }
            });
            this.setState({ msg: res.data.msg, token: res.data })
            localStorage.setItem('token', res.data.token)
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-success";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
            this.setState({
                loggedIn: true
            })
        } catch (error) {
            if (error.response.data.msg) {
                this.setState({ msg: error.response.data.msg })
            } else {
                this.setState({ msg: error.response.data.errors })
            }
            var newToast = document.getElementById("snackbar");
            newToast.className = "show bg-danger";
            setTimeout(function () { newToast.className = newToast.className.replace("show", ""); }, 3000);
        }
    }


    render() {
        if (this.state.loggedIn) {
            return <Redirect to="/dashboard" />
        }
        const { msg } = this.state;
        return (
            <div className="container">
                <div className="row" style={{ paddingTop: '50px' }}>
                    <div className="col-md-5 m-auto">
                        <div className="card mt-5 shadow mb-5">
                            <h3 className="card-header bg-dark text-center text-light">Login</h3>
                            <div className="card-body">
                                {msg.length ? msg.map(errMsg => <p key={errMsg.msg} id='snackbar' className="alert alert-danger" >{errMsg.msg}</p>) : null}
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="mobile">Mobile</label>
                                        <input onFocus='true' type="number" name="mobile" value={this.state.mobile} id="mobile" onChange={this.changeHandler} placeholder="Enter Mobile" className="form-control form-control-sm" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="password">Password</label>
                                        <input type="password" name="password" value={this.state.password} id="password" onChange={this.changeHandler} placeholder="Enter Password" className="form-control form-control-sm" />
                                    </div>
                                    <Link className="nav-link float-right" to="/Register">Dont have an Account?</Link>
                                    <Link className="nav-link float-right" to="/reset">Forget Password ?</Link>
                                    <button className="btn mt-3 btn-dark">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
