import React, {Component} from 'react'
import axios from 'axios';
import {Link} from 'react-router-dom'

export class userDashboard extends Component {
    constructor(props) {
        super(props);
        let loggedIn=false
        this.state={
            pans: 0,
            users:0,
            allPayments: 0,
            msg: {},
            loggedIn
        }
    }

    async componentDidMount() {
        try {
            // Get token from localStorage
            const token=localStorage.getItem('tokens')
            // Headers
            const config={
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token']=token;
            }
            const res=await axios.get(`/pan/total/pan/count`, config);
            this.setState({pans: res.data});

            const payments=await axios.get(`/api/total/payments/count`, config);
            this.setState({allPayments: payments.data});
            const totalUsers=await axios.get(`/api/total/users/count`, config);
            this.setState({users: totalUsers.data});
        } catch (error) {
            this.setState({msg: error.response.data})
            if (error.response.status==401) {
                this.setState({
                    loggedIn: false
                })
                localStorage.removeItem('tokens')
                this.props.history.push('/Admin')
            }
        }
    }

    render() {

        return (
            <main class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid py-5">
                    <div className="card">
                        <div className="card-header" >
                            <h3 className="text-center">Dashboard</h3>
                        </div>
                        <div className="card-body my-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <Link className="nav-link" to={'/allusers'}>
                                        <div className="card text-light" style={{
                                            backgroundImage: "linear-gradient(to right, #ec2F4B, #009FFF)"
                                        }}>
                                            <div className="card-header">
                                                <p className="text-center"><i className="fa fa-money fa-3x" aria-hidden="true"></i></p>
                                                <h3 className="text-center">Totel User</h3>
                                            </div>
                                            <div className="card-body">
                                                <h3 className="text-center">{this.state.users}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link className="nav-link" to={'/userAllPan'}>
                                        <div className="card shadow text-light" style={{
                                            backgroundImage: "linear-gradient(to right, #009FFF, #ec2F4B)"
                                        }}>
                                            <div className="card-header">
                                                <p className="text-center">
                                                    <i className="fa fa-id-card fa-3x" aria-hidden="true"></i>
                                                </p>
                                                <h3 className="text-center">Totel Pan Cards</h3>
                                            </div>
                                            <div className="card-body">
                                                <h3 className="text-center">{this.state.pans}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4">
                                    <Link className="nav-link" to="/payments">
                                        <div className="card text-light" style={{
                                            backgroundImage: "linear-gradient(to right, #ec2F4B, #009FFF)"
                                        }}>
                                            <div className="card-header">
                                                <p className="text-center"><i className="fa fa-money fa-3x" aria-hidden="true"></i></p>
                                                <h3 className="text-center">Totel Payments</h3>
                                            </div>
                                            <div className="card-body">
                                                <h3 className="text-center">{this.state.allPayments}</h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default userDashboard
