import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment'
import { Link, useHistory } from 'react-router-dom'
import Loader from '../Loader';
import UploadSlip from './UploadSlip';


const CompletePan = () => {
    const [pans, setPans] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loggedIn, setLoggedIn] = useState(true);
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const history = useHistory();
    const [search, setSearch] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterLimit, setFilterLimit] = useState(10);
    const [ack, setAck] = useState();
    const nextPage = () => {
        setSkip(skip + limit)
    }
    const previousPage = () => {
        setSkip(skip - limit)
    }
    const scrollToEnd = () => {
        setLimit(limit + 100)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    const getData = async (limit, skip) => {
        try {
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            await axios.get(`/pan/new/pans?limit=${limit}&skip=${skip}`, config)
                .then(res => {
                    const reverseData = res.data
                    setPans(reverseData)
                })
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                setLoggedIn(false)
                localStorage.removeItem('tokens')
                history.push('/Admin')
            }
        }
    }
    // const changeHandle = async (e) => {
    //     setSearch(e.target.value.toUpperCase())
    //     if (e.target.name === 'search') {
    //         try {
    //             // Get token from localStorage
    //             const token = localStorage.getItem('tokens')
    //             // Headers
    //             const config = {
    //                 headers: {
    //                     "Content-type": "application/json"
    //                 }
    //             };
    //             if (token) {
    //                 config.headers['x-admin-token'] = token;
    //             }
    //             const res = await axios.post(`/pan/search`, { text: search }, config)
    //             const reverseData = res.data.reverse()
    //             setPans(reverseData)
    //         } catch (error) {
    //             setMsg(error.response.data)
    //             if (error.response.status == 401) {
    //                 localStorage.removeItem('tokens')
    //                 history.push('/Admin')
    //             }
    //         }
    //     }
    // }
    const filterPan = async (e) => {
        e.preventDefault();
        try {
            // Get token from localStorage
            const token = localStorage.getItem('tokens')
            // Headers
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            };
            const data = {
                key: filter,
                limit: filterLimit,
                ack: ack
            }
            if (token) {
                config.headers['x-admin-token'] = token;
            }
            const res = await axios.post(`/pan/filter`, data, config)
            const reverseData = res.data.reverse()
            setPans(reverseData)
        } catch (error) {
            setMsg(error.response.data)
            if (error.response.status == 401) {
                localStorage.removeItem('tokens')
                history.push('/Admin')
            }
        }
    }
    useEffect(() => {
        getData(limit, skip);
    }, [limit, skip])

    return (
        <div>
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                <div className="container-fluid">
                    {/* <div className="input-group input-group-sm mb-3 col-md-2">
                        <span className="input-group-text col-md-2" id="inputGroup-sizing-sm">Search</span>
                        <input type="text" className="form-control" value={search}
                            onChange={changeHandle} aria-label="Sizing example input" name='search' aria-describedby="inputGroup-sizing-sm" />
                    </div> */}
                    <div className='filter'>
                        <select name="filter" id="filter" onChange={ (e) => setFilter(e.target.value) } value={ filter }>
                            <option>-Select-</option>
                            <option value="NEWPAN">NEW PAN</option>
                            <option value="REUPLOAD">REUPLOAD</option>
                            <option value="VERIFY">VERIFY</option>
                            <option value="REJECTED">REJECTED</option>
                            <option value="COMPLETED">COMPLETED</option>
                            <option value="ACK">ACKNOWLEDGEMENT</option>
                            <option value="ACK">CARD HOLDER</option>
                            <option value="ACK">MOBILE</option>
                        </select>
                        &nbsp;
                        { filter == 'ACK' ? <input type="text" value={ ack } onChange={ (e) => setAck(e.target.value) } name="ack" id="ack" placeholder='Acknowledgement' /> : null }
                        &nbsp;
                        <input type="number" value={ filterLimit } onChange={ (e) => setFilterLimit(e.target.value) } name="limit" id="limit" placeholder='limit' />
                        <input type="submit" value="Click" onClick={ (e) => filterPan(e) } className='bg-dark text-light' />
                    </div>
                    <div className="card mb-5">
                        <div className="card-header bg-dark">
                            <h4 className="text text-center text-light">New Pans</h4>
                        </div>
                        <div className="card-body shadow">
                            <div className="table-responsive">
                                <table id='myTable' className="table table-bordered shadow table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Pan Apply User</th>
                                            <th scope="col">Pan Holder</th>
                                            <th scope="col">Apply Date</th>
                                            <th scope="col">Pan Status</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Upload Slip</th>
                                            <th scope="col">Slip</th>
                                        </tr>
                                    </thead>
                                    {
                                        pans.length ? pans.map((pan, index) => {
                                            return <tbody key={ index }>
                                                <tr>
                                                    <th scope="row">{ index + 1 }</th>
                                                    <td>
                                                        <Link className="nav-link" to={ `/admin/allpanofuser/${pan.user}` }>
                                                            { pan.username }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link className="nav-link" to={ `/update/${pan._id}` }>
                                                            { pan.cardHolder }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Moment format='lll'>
                                                            { pan.date }
                                                        </Moment>
                                                    </td>
                                                    {/* <td>
                                                        <ul className="nav">
                                                            <li className="nav-item">
                                                                <Link className="nav-link" to={`/panfiles/${pan.image}`} target="true" >Image</Link>
                                                            </li>
                                                            <li className="nav item">
                                                                <Link to={`/panfiles/${pan.sig}`} className="nav-link" target="true" >Sig</Link>
                                                            </li>
                                                            <li className="nav item">
                                                                <Link to={`/panfiles/${pan.pdf}`} className="nav-link" target="true" >Document</Link>
                                                            </li>
                                                        </ul>
                                                    </td> */}
                                                    <td>{ pan.panStatus ? pan.panStatus : 'Pending' }</td>
                                                    <td>{ pan.email }</td>
                                                    <td>{ pan.contect_number }</td>
                                                    <td> <UploadSlip userId={pan._id} /></td>
                                                    <td>
                                                        { pan.slip ? <Link className='nav-link' target="true" to={ `/fornsdl/${pan.slip}` }>
                                                            { pan.acknowledgement }
                                                        </Link> : null }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }) : <h5>Please wait</h5>
                                    }
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a onClick={ previousPage } className="page-link" href="#">Previous</a>
                                    </li>
                                    <li className="page-item">
                                        <div onClick={ nextPage } className="page-link">Next</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )

}

export default CompletePan
